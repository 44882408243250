import './bootstrap';
import '../css/app.css';
import {createInertiaApp} from '@inertiajs/svelte'

createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear
        // during navigation, in milliseconds.
        delay: 250,

        // The color of the progress bar.
        color: '#ee9fbc',
        height: 50,
        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: false,
    },
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.svelte', {eager: true})
        let page = pages[`./Pages/${name}.svelte`]
        return {default: page.default, layout: page.layout}
    },
    setup({el, App, props}) {
        new App({target: el, props})
    },
})
