
<script>
    export let label;
    export let name;
    export let type;
    export let style = "primary";
    export let disabled;

    $: props = (({label, name, style, type, ...rest }) => rest)($$props);
</script>



<div class="form-control mt-6">
    <input type="{type}" value="{label}" name="{name}"class="btn btn-{style}" {disabled}>
</div>