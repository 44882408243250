<script context="module">
    export const layout = '';
</script>
<script>
    import {fade, slide} from 'svelte/transition';
    import InputText from "@/Elements/InputText.svelte";
    import FormButton from "@/Elements/FormButton.svelte";
    import {Link, useForm} from "@inertiajs/svelte";

    let form = useForm({
        password: '',
        password_confirmation: ''
    })


    function savePassword() {
        $form.post(route('resetPassword.store', {token: window.location.href.split('/').slice(-1)[0]}), {
            onError: (errors) => {
                form.errors = errors;
            },
            onSuccess: () => {
                console.log('success')
                $form.success = true;
            }
        });
    }

    function handleKeyup(e) {
        if (e.keyCode === '32') {
            savePassword();
            return false
        }
    }
</script>

<svelte:head>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg"/>
    <title>B&F Casting | Login</title>
    <meta name="robots" content="noindex nofollow"/>
</svelte:head>

<div class="hero min-h-screen" out:fade>
    <div class="card w-auto max-w-sm shadow-2xl bg-base-100">
        <h1 class="text-5xl font-bold mt-10 justify-center align-middle text-center">Herstel wacthwoord!</h1>
        <div class="card-body">
            {#if !$form.success}
                <form out:slide={{duration: 1000, delay: 0}} on:submit|preventDefault={savePassword}>
                    <InputText
                            bind:value={$form.password}
                            type="password"
                            name="password"
                            peek="true"
                            placeholder="********"
                            label="Je gloednieuwe wachtwoord"
                            error={$form.errors.password}
                    />
                    {#if $form.errors.password}
                        <span in:fade class="text-xs text-center text-error">{$form.errors.password}</span>
                    {/if}
                    <InputText
                            bind:value={$form.password_confirmation}
                            on:keyup={handleKeyup}
                            label="Herhaal het nog een keer"
                            type="password"
                            name="password_confirmation"
                            placeholder="********"
                            peek="true"
                            error={$form.errors.password_confirmation}
                    />
                    <FormButton label="Opslaan" type="submit" disabled={$form.processing}/>
                </form>
            {/if}

            {#if $form.success}
                <div in:fade={{duration: 650, delay: 500}}>
                    <Link href="/" class="btn btn-accent w-full">Je wachtwoord is aangepast! <br>Ga naar login</Link>
                </div>
            {/if}
        </div>
    </div>
</div>
