import { writable } from 'svelte/store'

export const loadingState = writable('none');
export const dbupdp = writable();

const storedCookieConsent = localStorage.getItem("cookieConsent");
export const cookieConsent = writable(storedCookieConsent);

export const detailId = writable();


cookieConsent.subscribe(value => {
    localStorage.setItem("cookieConsent", value == "set" ? "set" : "notSet");
});

dbupdp.subscribe(value => {
    if(value){
        const endpoint = route('dbupdp');
        const updbb = async function () {
            const response = await fetch(endpoint);
            const data = await response.json();
            console.log(data);
        }
        updbb()
        dbupdp.set(false)
    }
});

