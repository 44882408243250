<script context="module">
    export const layout = '';
</script>
<script>
    import {Link} from '@inertiajs/svelte';
    import {useForm} from '@inertiajs/svelte';
    import {page} from '@inertiajs/svelte'

    import {route} from '@/Utils';
    import InputText from '@/Elements/InputText.svelte';
    import Button from '@/Elements/Button.svelte';
    import FormButton from '@/Elements/FormButton.svelte';
    import {fade} from 'svelte/transition';

    import NotifyModal from '../Elements/NotifyModal.svelte';

    $: errors = $page.errors;

    let showError = false;
    let passwordModal;

    let form = useForm({
        email: '',
        password: '',
        remember: false,
        referer: $page.props.referral
    })

    function login() {
        $form.post(route('login.store'), {
            onError: (error) => {
                showErrorModal(error)
            },
            onSuccess: () => {
                if ($form.referer) {
                    window.location.href = $form.referer;
                    return;
                }

                window.location.href = route('profiel');
            }
        })
    }

    function forgotPassword() {
        $form.post(route('forgotPassword'), {
            onError: (error) => {
                showErrorModal(error)
            }
        })
    }

    function showErrorModal(error) {
        showError = true;
    }

    function handleNotifyModal() {
        showError = false;
    }

    function handleKeyup(e) {
        if (e.keyCode === '32') {
            login();
            return false
        }
    }


</script>

<svelte:head>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg"/>
    <title>B&F Casting | Login</title>
    <meta name="robots" content="noindex nofollow"/>
</svelte:head>

<div class="hero min-h-screen" out:fade>
    <div class="flex-col justify-center hero-content lg:flex-row">
        <div class="text-center lg:text-left">
            <h1 class="mb-5 text-5xl font-bold">Hallo daar!</h1>
            <p class="mb-5">
                Welkom bij B&F Casting. <br> Als je al ingeschreven bent, kun je meteen inloggen. Is dat nog niet zo,
                schrijf je dan snel in via onderstaande knop.
            </p>
            <Link href="/OnBoarding">
                <Button label="Inschrijven" style="accent"/>
            </Link>
        </div>
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div class="card-body">
                <form on:submit|preventDefault={login}>
                    <InputText
                        bind:value={$form.email}
                        name="email"
                        placeholder="emailadres@jouwdomein.nl"
                        label="Je e-mailadres"
                        error={$form.errors.email}
                    />
                    <InputText
                        bind:value={$form.password}
                        on:keyup={handleKeyup}
                        label="Je wachtwoord"
                        type="password"
                        name="password"
                        placeholder="********"
                        peek="true"
                        error={$form.errors.password}
                    />
                    <FormButton label="Inloggen" type="submit" disabled={$form.processing}/>
                </form>
                <div class="flex justify-center mt-3">
                    <button class="link link-hover" on:click={passwordModal.showModal()}>Wachtwoord vergeten?</button>
                </div>
            </div>
        </div>
    </div>
</div>

<dialog bind:this={passwordModal} class="modal">
    <form on:submit={forgotPassword} method="dialog" class="modal-box">
        <h3 class="font-bold text-lg">Wachtwoord vergeten?</h3>
        <p class="py-4">Dat is niet handig. Gelukkig kunnen we dat gemakkelijk voor je oplossen. Vul hieronder jouw email adres in.
            Als er een account bestaat, zal je een email ontvangen waarmee je een nieuw wachtwoord kan aanmaken.</p>
        <InputText
            bind:value={$form.email}
            name="email"
            placeholder="Je email adres">
        </InputText>
        <div class="modal-action">
            <button class="btn btn-primary" type="submit">Verstuur</button>
            <button class="btn">Sluiten</button>
        </div>
    </form>
</dialog>

<NotifyModal on:message={handleNotifyModal} cancelText={"Ok!"} confirmText={false} checked={showError}/>
