<script>
    import {createEventDispatcher, onDestroy} from 'svelte';
    import Time from "../Elements/Time.svelte";
    import Fa from 'svelte-fa/src/fa.svelte'
    import {faInfo} from "@fortawesome/free-solid-svg-icons";
    import {detailId} from '../Utils/stores';
    import MediaQuery from './MediaQuery.svelte';


    export let job;
    export let checked;
    export let mode;

    let dateFormat = "dd DD MMM";

    if (mode == "history") {
        dateFormat = "DD MMM YYYY"
    }

    if (checked) {
        job.disabled = true;
    }

    function NoClick() {
        return null;
    }

</script>

<tr class="group">
    <th class="group-hover:bg-gray-100">
        <label>
            {#if mode !== "history"}
                <input type="checkbox" class="checkbox cursor-default" class:checkbox-success={checked} bind:checked={checked}
                       on:click|preventDefault={NoClick}/>
            {/if}
        </label>
    </th>

    <MediaQuery query="(min-width: 1024px)" let:matches>
        {#if matches}
            <td class="group-hover:bg-gray-100">
                <div>
                    <div class="font-bold">{job.name}</div>
                    <span class="badge badge-ghost badge-sm">€{job.rate_subscriber / 100},-</span>
                </div>
            </td>
            <td class="group-hover:bg-gray-100">
                <div>{job.client_name}</div>
                <div class="text-sm opacity-50">{job.address}</div>
            </td>
            <td class="group-hover:bg-gray-100">
                {#if mode !== "history"}
                    <button class="btn btn-accent btn-circle" on:click={() => {detailId.set(job.id)}}>
                        <Fa icon={faInfo}/>
                    </button>
                {/if}
            </td>
        {:else}
            <td on:click={() => {detailId.set(job.id)}} class="group-hover:bg-gray-100">
                <div class="mb-5">
                    <div class="font-bold">{job.name}</div>
                    <div class="text-sm opacity-50">{job.address}</div>
                </div>
                {job.client_name}
                <br/>
                <span class="badge badge-ghost badge-sm">€{job.rate_subscriber / 100},-</span>
                <span class="badge badge-ghost badge-sm md:invisible"><Time timestamp={job.date}
                                                                            format="dd DD MMM"/></span>
            </td>
        {/if}
    </MediaQuery>
    <MediaQuery query="(min-width: 1024px)" let:matches>
        {#if matches}
            <td class="group-hover:bg-gray-100">
                <Time timestamp={job.date} format={dateFormat}/>
            </td>
        {/if}
    </MediaQuery>

</tr>
