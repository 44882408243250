<script context="module">
    export {default as layout} from '../Layout/Layout.svelte'
</script>
<script>
    import {Link, page, router, useForm} from '@inertiajs/svelte'
    import MediaQuery from '../Elements/MediaQuery.svelte';
    import RolesRow from '../Elements/RolesRow.svelte';
    import {fade} from 'svelte/transition';
    import {onMount} from "svelte";
    import DetailModal from '../Elements/DetailModal.svelte';
    import {detailId} from '@/Utils/stores';
    import PaymentStatus from "../Components/PaymentStatus.svelte";

    const route = window.route;
    let checked = false;

    export let jobs = [];
    let show;
    let errors;

    let detail = {};
    let invisible = false;
    let subscribeForm = useForm({
        role_slug: null,
        role_guests: 0
    });

    const gifs = [
        'https://media.tenor.com/QjfD0Mx8Mp8AAAAd/thanks-thank-you.gif',
        'https://media.tenor.com/OBg23ghDrrkAAAAC/thank-you.gif',
        'https://media.tenor.com/6bUnLYb54jgAAAAC/thank-you-thanks.gif',
        'https://media.tenor.com/pEhHUJTIO6UAAAAC/thank-you-simon-cowell.gif',
        'https://media.tenor.com/UMHdiHRwRAsAAAAC/thanks-thank-you.gif',
        'https://media.tenor.com/jCKHFZaQSLwAAAAC/thanks.gif',
        'https://media.tenor.com/rskeqG9jhawAAAAC/kevin-office.gif'
    ]

    let rGif = '';

    detailId.subscribe(value => {
        if (value) {
            checked = true
            detail = jobs.filter(function (i) {
                return i.id === value;
            })[0];
        }
    })

    function subscribeRole() {
        $subscribeForm.role_slug = detail.slug;
        window.axios.patch('/rollen', {role_slug: detail.slug, role_guests: 0})
            .then(response => {
                checked = false;
                let index = jobs.findIndex((obj => obj.slug === detail.slug));
                jobs[index].subscribed = true;
                rGif = randomGif();
                document.getElementById("register_success").showModal();
            });

    }


    function handleNotifyModal(e) {
        if (e.detail.text == "confirm") {
            //subscribe
            subscribeRole()
        } else {
            checked = false;
            detailId.set(false)
        }
    }

    //Comparer Function
    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function orderBy(prop) {
        jobs = jobs.sort(GetSortOrder(prop));
    }

    onMount(async () => {
        if (jobs === null || jobs === undefined) {
            jobs = [];
        }

        return router.on('before', (event) => {
            invisible = "invisible"
        })

    })

    function randomGif() {
        return gifs[Math.floor(Math.random() * gifs.length)];
    }
</script>

<svelte:head>
    <title>B&F Casting | Beschikbare Rollen</title>
</svelte:head>

<!--Main -->
<div id="roles-content" class="w-full h-full flex-1 pt-4 {invisible}" in:fade={{ delay: 500 }}>

    <div class="error w-full">
        <PaymentStatus paymentStatus={$page.props.paymentStatus} justPaid={$page.props.justPaid}/>
    </div>

    <div class="overflow-x-auto w-full">
        {#if jobs && jobs.length > 0}

            <table class="table w-full">
                <!-- head -->
                <thead>
                <tr>
                    <th></th>

                    <th on:click={() => {orderBy("client_name")}}>Naam</th>

                    <MediaQuery query="(min-width: 1024px)" let:matches>
                        {#if matches}
                            <th>Titel</th>

                            <th on:click={() => {orderBy("date")}}>Datum</th>

                            <th></th>
                        {/if}
                    </MediaQuery>


                </tr>
                </thead>
                <tbody>
                <!-- row  -->
                {#each jobs as job, i}
                    <RolesRow job={job} checked={job.subscribed}></RolesRow>
                {/each}
                </tbody>
                <!-- foot -->
                <tfoot>
                <tr>
                    <th>

                    </th>
                    <th>Naam</th>
                    <MediaQuery query="(min-width: 1024px)" let:matches>
                        {#if matches}
                            <th>Titel</th>

                            <th>Datum</th>

                            <th></th>
                        {/if}
                    </MediaQuery>
                </tr>
                </tfoot>

            </table>
        {:else}
            <div class="flex-row flex justify-center">
                <div class="card mb-20 max-w-md min-w-fit glass shadow-xl">
                    <div class="card-body">
                        <h2 class="card-title">Geen rollen beschikbaar</h2>
                        <p>
                            Er zijn momenteel geen rollen beschikbaar. Kom hier later nog eens terug.
                        </p>
                        <div class="card-actions justify-end">
                            <button class="btn mt-5 btn-primary">
                                <Link href="/profiel">Terug naar profiel</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        {/if}
    </div>

</div>
<DetailModal on:message={handleNotifyModal} title={detail.name} text={detail.description} disabled={detail.disabled}
             reason={detail.disabled_reason} {checked}/>

<dialog id="register_success" class="modal">
    <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg">Dankjewel voor je inschrijving!</h3>
        <p class="py-4">Dit betekent nog niet dat je de rol hebt! Wij presenteren jouw profiel aan de opdrachtgever, je ontvangt nog bericht of je naar de set mag komen!</p>
        <div class="gif p-5 grid place-content-center">
            <img
                    class="min-w-full max-h-56 rounded-xl"
                    src="{rGif}}"
                    alt="T. Hanks"
            />
        </div>
        <div class="modal-action">
            <button class="btn btn-primary">Okay!</button>
        </div>
    </form>
</dialog>

