<script>

    export let knownTypes = [];
    export let subscriberTypes = [];

    export let error = null;
    export let label = '';

    function updateSubscriberType(typeId) {
        if (isSubscriberType(typeId)) {
            subscriberTypes = subscriberTypes.filter((t) => t.id !== typeId);
            return;
        }

        subscriberTypes = [...subscriberTypes, knownTypes.find((t) => t.id === typeId)];
    }

    function isSubscriberType(typeId) {
        return subscriberTypes.find((t) => t.id === typeId) !== undefined;
    }

</script>
<div>
    <span class="label">{label}</span>
    <div class="flex justify-start flex-col flex-wrap max-h-[100px] {error ? 'border border-error' : ''}">

        {#each knownTypes ?? [] as type}
            <label class="label p-1 mr-10 flex cursor-pointer">
                <span class="label-text">{type.name}</span>
                <input type="checkbox" on:change={updateSubscriberType(type.id)}
                       checked={isSubscriberType(type.id) ? 'checked' : ''} class="checkbox checkbox-primary "/>
            </label>
        {/each}
    </div>
</div>
