import urlParser from "js-video-url-parser";

urlParser.plugins.youtube.parseVideoUrl = function(url) {
  let match = url.match(
      /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i
  );
  if(match) {
    return match[1];
  }
  // match YouTube shorts
  match = url.match(/youtube.com\/shorts\/([\w-]{11})/i);
  if(match) {
    return match[1];
  }
  return undefined;
};

export const videoTypeParser = (url) => {
  const isMp4 = String(url).match(/^http(s?):\/\/(\S+).mp4$/g);
  const isWebm = String(url).match(/^http(s?):\/\/(\S+).webm$/g);

  if (isMp4) {
    return { type: "video/mp4", id: null };
  }

  if (isWebm) {
    return { type: "video/webm", id: null };
  }

  let parsed;

  try {
    parsed = { type: urlParser.parse(url).provider, id: urlParser.parse(url).id }
  } catch (Exception) {
    parsed = { type: null, id: null };
  }

  return parsed;
};
