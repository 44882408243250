<script>
    import {faCoins} from "@fortawesome/free-solid-svg-icons";
    import Fa from 'svelte-fa/src/fa.svelte'

    let subscriptionType;

    function openPaymentModal() {
        document.getElementById('payment_modal').showModal();
    }

    function handlePayment() {
        window.location.href = route('profiel.payment', {paymentType: subscriptionType});
    }

</script>

<div class="alert alert-error my-5">
    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>
    <span>Je hebt (nog) niet betaald voor jouw B&F Casting profiel. Je kan het systeem niet gebruiken totdat jouw betaling is gelukt! Klik op de knop om te betalen</span>
    <div>
        <div>
            <button class="btn btn-sm btn-primary" on:click={openPaymentModal}>
                <Fa icon="{faCoins}"  />Betaal nu!</button>
        </div>
    </div>
</div>

<dialog id="payment_modal" class="modal">
    <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg">Betaal om gerbuik te maken van ons platform!</h3>
        <p>Kies jouw versie:</p>
        <div class="form-control">
            <label class="label cursor-pointer">
                <label for="payment-opt-8" class="label-text">Zonder online publicatie: €8,-</label>
                <input bind:group={subscriptionType} value="{'nonPublic'}" type="radio" id="payment-opt-8" name="radio-10" class="radio checked:bg-red-500" checked />
            </label>
        </div>
        <div class="form-control">
            <label class="label cursor-pointer">
                <label for="payment-opt-15" class="label-text">Met online publicatie: €15,-</label>
            <input bind:group={subscriptionType} value="{'public'}" type="radio" id="payment-opt-15"  name="radio-10" class="radio checked:bg-blue-500" checked />
            </label>
        </div>
        <div class="modal-action">
            <!-- if there is a button in form, it will close the modal -->
            <button class="btn">Sluiten</button>
            <button class="btn btn-primary text-white" on:click={handlePayment}>Betalen!</button>
        </div>
    </form>
</dialog>
