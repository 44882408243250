<script>
    import {fade, fly, slide} from 'svelte/transition';
    import {Link, page} from "@inertiajs/svelte";
    import {route} from "@/Utils";
    import {onMount} from "svelte";
    import SelectBox from "../Elements/SelectBox.svelte";
    import InputText from "../Elements/InputText.svelte";
    import axios from "axios";
    import SubscriberTypes from "../Elements/SubscriberTypes.svelte";
    import ProfileImages from "../Components/ProfileImages.svelte";

    let step = 1;
    let error = '';
    let createError = '';
    let errorFields = {};
    let personalError = '';
    let detailsError = '';
    let detailsErrorFields = {};

    const ethnicities = $page.props.ethnicities?.map((e) => {
        return {value: '' + e.id, name: e.name}
    })
    const eyecolors = $page.props.eyecolors?.map((e) => {
        return {value: '' + e.id, name: e.name}
    })
    const haircolors = $page.props.haircolors?.map((e) => {
        return {value: '' + e.id, name: e.name}
    })

    let newUser = {
        firstname: '',
        preposition: '',
        lastname: '',
        birthdate: '',
        phone_mobile: '',
        sex: '',
        email: '',
        bsn: '',
        iban: '',
        street: '',
        street_number: '',
        street_number_addition: '',
        zipcode: '',
        city: ''
    };

    let profileDetails = {
        ethnicity_id: '',
        hair_color_id: '',
        eye_color_id: '',
        height: 0,
        weight: 0,
        size_shoe: 0,
        size_dress: '',
        types: [],
        images: [],
        userId: $page.props.user_id
    }

    let newPassword = {
        password: '',
        password_confirmation: '',
    }

    let newUserId = null;
    let verifiedUserId = null;
    let uploading = false;

    let subscriptionType = '';

    const sexOptions = [
        {value: 'M', name: 'Man'},
        {value: 'F', name: 'Vrouw'},
        {value: 'T', name: 'Anders'},
    ]

    onMount(() => {
        const verified = $page.props.verified ?? null;
        const paid = $page.props.paid ?? null;
        verifiedUserId = $page.props.verifiedUserId;

        if (verified) {
            step = 6;
            removeEventListener("beforeunload", beforeUnloadListener, { capture: true });

            if (paid) {
                removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
                step = 7;
            }
        }

        if (verified === false) {
            step = 1;
            error = 'token_invalid';
        }
    });

    function proceed() {
        const startStep = step;

        switch (step) {
            case 1:
                step = step + 1;
                break;
            case 2:
                step = step + 1;
                addEventListener("beforeunload", beforeUnloadListener, { capture: true });
                break;
            case 3:
                if (!validateNewUserData()) {
                    break;
                }

                axios.post(route('onboarding.validate'), {user: newUser}).then((response) => {
                    step = step + 1;
                }).catch((error) => {
                    personalError = error.response.data.message;
                    errorFields = error.response.data.errors;
                });

                break;
            case 4:
                if (!validatePassword()) {
                    break;
                }
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LewVuMoAAAAAPnR9eWW3DZLuZpKe05qRPxTV5HN', {action: 'submit'}).then(function (token) {
                        axios.post(route('onboarding.create'), {
                            user: newUser,
                            recaptcha: token,
                            password: newPassword
                        }).then((response) => {
                            step = step + 1;
                            newUserId = response.data.user_id;
                        }).catch((error) => {
                            createError = error;
                        });
                    });
                });

                break;
            case 5:
                step = step + 1;
                break;
            case 6:
                step = step + 1;
                break;
            case 7:
                uploading = true;
                axios.post('/OnBoarding/profileData', {profileDetails}, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    uploading = false;
                    toProfile();
                }).catch((error) => {
                    detailsError = error.response.data.message;
                    detailsErrorFields = error.response.data.errors;
                    uploading = false;
                });
        }

        if (startStep < step) {
            document.getElementById('sign-up-steps').scrollTo({
                left: document.getElementById('sign-up-steps').scrollLeft + convertRemToPixels(7),
                behavior: 'smooth'
            });
        }
    }

    function convertRemToPixels(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    function back() {
        step = step - 1;
        document.getElementById('sign-up-steps').scrollTo({
            left: document.getElementById('sign-up-steps').scrollLeft - convertRemToPixels(7),
            behavior: 'smooth'
        });
    }

    function toOnboarding() {
        window.location.href = route('onboarding');
    }

    function toProfile() {
        window.location.href = route('profiel');
    }

    function goToPayment() {
        window.location.href = route('onboarding.payment', {paymentType: subscriptionType, userId: verifiedUserId});
    }

    function validateNewUserData() {
        // Validate all values in newUser dynamically and set error messages if they are not valid.
        errorFields = {};
        let valid = true;

        for (const [key, value] of Object.entries(newUser)) {
            let setError = false;

            if (key === 'street_number_addition' || key === 'preposition') { // Not required
                continue;
            }

            // The types array cannot be empty
            if (!value) { // Any other value cannot be empty or zero
                setError = true;
            }

            if (setError) {
                errorFields[key] = true;
                personalError = 'Vul alle verplichte velden in.';
                valid = false;

            }
        }

        return valid;
    }

    function validatePassword() {
        if (newPassword.password === '') {
            createError = 'Je wachtwoord is verplicht.';
            return false;
        }

        if (newPassword.password.length < 8) {
            createError = 'Je wachtwoord moet minimaal 8 tekens lang zijn.';
            return false;
        }

        if (newPassword.password !== newPassword.password_confirmation) {
            createError = 'De wachtwoorden komen niet overeen.';
            return false;
        }

        createError = '';
        return true;
    }

    const beforeUnloadListener = (event) => {
        event.preventDefault();
        return (event.returnValue = "");
    };

</script>

<svelte:head>
    <title>B&F Casting | Onboarding</title>
    <script src="https://www.google.com/recaptcha/api.js?render=6LewVuMoAAAAAPnR9eWW3DZLuZpKe05qRPxTV5HN"></script>
</svelte:head>

{#if error === 'token_invalid'}
    <div class="mx-auto px-4 my-16 alert alert-error">
        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <span>Deze link is helaas niet meer geldig. Vraag een nieuwe aan.</span>
        <div>
            <button class="btn btn-sm" on:click={toOnboarding}>Vraag nieuwe aan!</button>
        </div>
    </div>
{:else}

    <div class="overflow-x-auto scroll-smooth">
        <ul class="w-full overflow-hidden steps my-5" id="sign-up-steps" in:fade={{delay:500}}>
            <li class="step !min-w-[7rem]" class:step-success={step >= 1}>Welkom</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 2}>Privacy</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 3}>Wie ben je?</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 4}>Login gegevens</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 5}>Check je mail!</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 6}>Inschrijfkosten</li>
            <li class="step !min-w-[7rem]" class:step-success={step >= 7}>Geslaagd!</li>
        </ul>
    </div>

    <div class="hero min-h-screen glass p-10" in:fade>
        {#if step === 1}
            <div class="text-center hero-content">
                <div class="max-w-md" in:fly="{{ x: 200, duration: 500, delay: 500 }}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        Leuk! Welkom.
                    </h1>
                    <p class="mb-5">
                        Een profiel bij B&F Casting is het begin van je acteercarriere. In de
                        komende stappen leren we je graag kennen en helpen we je een mooi profiel samen te stellen.
                    </p>

                    <button class="btn mr-5">
                        <Link href="/">Terug naar inloggen</Link>
                    </button>
                    <button class="btn text-white btn-primary" on:click={proceed}>Verder</button>
                </div>
            </div>
        {/if}


        {#if step === 2}
            <div class="text-center hero-content">
                <div class="max-w-md" in:fly="{{ x: 200, duration: 500, delay: 500}}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        Privacy.
                    </h1>
                    <p class="mb-5">
                        En uiteraard gaan we zorgvuldig met je gegevens om. Dat vinden we erg belangrijk. In <a
                        class="text-info hover:text-primary transition-colors" target="_blank"
                        href="https://www.benfcasting.nl/privacyverklaring/">onze privacy verklaring</a>
                        leggen we uit hoe we dat precies doen. Mocht je hier vragen over hebben, kun je altijd even
                        contact
                        opnemen!
                    </p>

                    <p class="mb-5 text-sm italic">Indien je klikt op de knop 'Verder' ga je akkoord met de
                        bovengenoemde privacy verklaring.</p>

                    <button class="btn text-white btn-primary" on:click={proceed}>Verder</button>
                </div>
            </div>
        {/if}

        {#if step === 3}
            <div class="text-center hero-content">
                <div class="max-w-2xl" in:fly="{{ x: 200, duration: 500, delay:500}}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        Wie ben je?
                    </h1>
                    <p class="mb-5">
                        We willen graag weten wie je bent. Vul hieronder jouw persoonsgegevens in.
                    </p>

                    {#if personalError}
                        <div in:slide class="mb-5 alert text-white alert-error">
                            {personalError}
                        </div>
                    {/if}

                    <div class="card shadow bg-white">
                        <div class="card-body">
                            <div class="flex flex-row gap-5 mb-5 pb-10 border-b border-gray-200">
                                <InputText bind:error={errorFields.firstname} name="first-name" label="Je voornaam"
                                           placeholder="John" required
                                           bind:value={newUser.firstname}/>
                                <InputText bind:error={errorFields.preposition} name="preposition" label="Tussenvoegsel"
                                           placeholder="Van" bind:value={newUser.preposition} />
                                <InputText bind:error={errorFields.lastname} name="lname" label="Je achternaam"
                                           placeholder="Doe" required
                                           bind:value={newUser.lastname}/>
                            </div>
                            <div class="flex flex-row gap-5">
                                <SelectBox bind:error={errorFields.sex} required options="{sexOptions}" name="sex"
                                           label="Gelsacht" bind:value={newUser.sex}/>
                                <InputText bind:error={errorFields.birthdate} name="birthdate" type="date"
                                           label="Geboortedatum"
                                           required
                                           placeholder="05-12-1987" bind:value={newUser.birthdate}/>
                            </div>
                            <div class="mb-5 pb-10 border-b border-gray-200">
                                <div class="flex flex-row gap-5 mb-3">
                                    <InputText bind:error={errorFields.phone_mobile} name="mobilePhone"
                                               label="Mobiele telefoon"
                                               required
                                               placeholder="06-12345678" bind:value={newUser.phone_mobile}/>
                                    <InputText bind:error={errorFields.email} name="email" type="email" id="email" label="E-mailadres" required
                                               placeholder="E-mailadres" bind:value={newUser.email}/>
                                </div>
                                <span class="text-sm text-left text-gray-400">Deze gegevens hebben we nodig om je te kunnen bereiken indien nodig!</span>
                            </div>
                            <div class="mb-5 pb-10 border-b border-gray-200">
                                <div class="flex flex-row gap-5">
                                    <InputText label="Straat" name="street-address"  placeholder="Julianalaan" bind:error={errorFields.street}
                                               required bind:value={newUser.street}></InputText>
                                    <InputText label="Huisnummer" name="number" placeholder="55" required
                                               bind:error={errorFields.street_number}
                                               bind:value={newUser.street_number}></InputText>
                                    <InputText cssContainer="max-w-[100px]" name="addition" id="addition"
                                               bind:error={errorFields.street_number_addition} placeholder=""
                                               label="Toevoeging"
                                               bind:value={newUser.street_number_addition}></InputText>
                                </div>
                                <div class="flex flex-row gap-5 mb-3">
                                    <InputText required label="Postcode" name="postal-code" id="postal_code" placeholder="0000 AA"
                                               bind:error={errorFields.zipcode}
                                               bind:value={newUser.zipcode}></InputText>
                                    <InputText required label="Stad" name="city" id="city" placeholder="Hilversum"
                                               bind:error={errorFields.city} bind:value={newUser.city}></InputText>
                                </div>
                                <span class="text-sm text-left text-gray-400">Deze gegevens hebben we nodig om jouw betaling aan een adres te koppelen! </span>
                            </div>
                            <div>
                                <div class="flex flex-row gap-5 mb-3">
                                    <InputText required label="BSN" placeholder="123456789"
                                               bind:error={errorFields.bsn} bind:value={newUser.bsn}></InputText>
                                    <InputText required label="IBAN" placeholder="NL00ABNB0123456789"
                                               bind:error={errorFields.iban} bind:value={newUser.iban}></InputText>
                                </div>
                                <span class="text-sm text-left text-gray-400">Deze gegevens hebben we nodig om je te kunnen uitbetalen na het uitvoeren van een rol! </span>
                            </div>
                        </div>
                    </div>
                    <button class="btn mt-5 text-white btn-primary" on:click={proceed}>Verder</button>
                </div>
            </div>
        {/if}

        {#if step === 4}
            <div class="text-center hero-content">
                <div class="max-w-md" in:fly="{{ x: 200, duration: 500, delay: 500}}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        Hi {newUser.firstname} {newUser.lastname}!
                    </h1>
                    <p class="mb-5">
                        Om in te loggen hebben we ook een wachtwoord van je nodig! Hieronder mag je er een bedenken, en vul die daarnaa nog een keer in ter controle.
                    </p>

                    {#if createError}
                        <div in:slide class="text-white mb-5 alert alert-error">
                            {createError}
                        </div>
                    {/if}

                    <div class="card shadow bg-white mb-5">
                        <div class="card-body">
                            <InputText bind:error={createError} name="new-password" label="Je wachtwoord" type="password"
                                       placeholder="********" peek="true"
                                       bind:value={newPassword.password}/>
                            <InputText bind:error={createError} name="new-password" label="Herhaal je wachtwoord"
                                       type="password"
                                       placeholder="********" peek="true"
                                       bind:value={newPassword.password_confirmation}/>
                            <span class="text-sm text-left text-gray-400">Je wachtwoord moet minimaal 8 tekens lang zijn.</span>
                        </div>
                    </div>
                    <button class="btn mr-5" on:click={back}>Terug</button>
                    <button class="btn text-white btn-primary" on:click={proceed}>Verder</button>
                </div>
            </div>

        {/if}

        {#if step === 5}
            <div class="text-center hero-content">
                <div class="max-w-md" in:fly="{{ x: 200, duration: 500, delay:500}}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        You've got mail!
                    </h1>
                    <p class="mb-5">
                        We hebben je een bericht gestuurd.
                        En een linkje waar je even op moet klikken.
                    </p>
                    <div class="flex items-center justify-center">
                        <img src="https://media.giphy.com/media/57n8B4SoyreVO/giphy.gif" alt="tom hanks gifje"
                             width="300"
                             class="rounded-lg shadow-lg">
                    </div>
                </div>
            </div>
        {/if}

        {#if step === 6}
            <div class="text-center hero-content">
                <div class="max-w-md" in:fly="{{ x: 200, duration: 500, delay:500}}" out:fade>
                    <h1 class="mb-5 text-5xl font-bold">
                        Voor niks gaat de zon op ☀️
                    </h1>
                    <p class="mb-5">
                        Je hebt je account geverifieerd. Nu moet je nog even een eenmalige betaling doen.
                    </p>

                    <div class="card shadow bg-white">
                        <div class="card-body">
                            <div class="form-control">
                                <label class="label cursor-pointer">
                                    <label for="payment-opt-8" class="label-text">Zonder online publicatie: €8,-</label>
                                    <input bind:group={subscriptionType} value="{'nonPublic'}" type="radio"
                                           id="payment-opt-8" name="radio-10" class="radio checked:bg-red-500" checked/>
                                </label>
                            </div>
                            <div class="form-control">
                                <label class="label cursor-pointer">
                                    <label for="payment-opt-15" class="label-text">Met online publicatie: €15,-</label>
                                    <input bind:group={subscriptionType} value="{'public'}" type="radio"
                                           id="payment-opt-15" name="radio-10" class="radio checked:bg-blue-500"
                                           checked/>
                                </label>
                            </div>
                            <em class="text-sm text-gray-500">
                                Online publicatie betekent dat je profiel op de <a
                                href="https://cma.benfcasting.nl/index.php/zoeken">website van B&F Casting</a>
                                gevonden
                                kan worden.
                            </em>
                        </div>
                    </div>
                    <button class="btn mt-5 text-white btn-primary" disabled={!subscriptionType} on:click={goToPayment}>
                        Betalen
                    </button>
                </div>
            </div>
        {/if}

        {#if step === 7}
            <div class="text-center hero-content">
                <div class="max-w-xl" in:fly="{{ x: 200, duration: 500, delay:500}}" out:fade>
                    <div class="card shadow bg-white">
                        <div class="card-body">

                            <h1 class="mb-5 text-5xl font-bold">
                                Bedankt voor je betaling!
                            </h1>
                            <p class="mb-5">
                                Je hebt betaald! We hebben nog iets meer gegevens nodig om je profiel compleet te maken.
                            </p>

                            {#if detailsError}
                                <div in:fade class="mb-5 alert text-white alert-error">
                                    {detailsError}
                                </div>
                            {/if}

                            <div class="mb-5 pb-10 border-b border-gray-200">
                                <div class="flex flex-row gap-5">
                                    <SelectBox options={ethnicities} error="{detailsErrorFields.ethnicity_id}"
                                               bind:value={profileDetails.ethnicity_id}
                                               label="Ethniciteit" required="true"/>
                                    <SelectBox options={eyecolors} error="{detailsErrorFields.eye_color_id}" bind:value={profileDetails.eye_color_id}
                                               label="Kleur ogen" required="true"/>
                                    <SelectBox options={haircolors} error="{detailsErrorFields.hair_color_id}"
                                               bind:value={profileDetails.hair_color_id}
                                               label="Haarkleur" required="true"/>
                                </div>
                            </div>
                            <div>
                                <div class="flex flex-col gap-5 mb-5 pb-10 border-b border-gray-200">
                                    <div>
                                        <label class="label" for="length">
                                            <span class="label-text">Lengte</span>
                                        </label>
                                        <div class="flex flex-row gap-5">
                                            <input id="length" type="range" min="40" max="250"
                                                   class="range ml-[3px] range-success range-sm flex-1 {detailsErrorFields.height ? 'border border-error' : ''}" name="length"
                                                   bind:value={profileDetails.height}/>
                                            <span>{profileDetails.height} cm</span>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="label" for="weight">
                                            <span class="label-text">Gewicht</span>
                                        </label>
                                        <div class="flex flex-row gap-5">
                                            <input id="weight"
                                                   type="range"
                                                   min="20"
                                                   max="210"
                                                   class="range ml-[3px] range-success range-sm flex-1 {detailsErrorFields.weight ? 'border border-error' : ''}"
                                                   name="weight" bind:value={profileDetails.weight}/>
                                            <span>{profileDetails.weight} kg</span>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="label" for="shoesize">
                                            <span class="label-text">Schoenmaat</span>
                                        </label>
                                        <div class="flex flex-row gap-5">
                                            <input id="shoesize"
                                                   type="range"
                                                   min="16"
                                                   max="53"
                                                   step="1"
                                                   class="range ml-[3px] range-success range-sm flex-1 {detailsErrorFields.size_shoe ? 'border border-error' : ''}"
                                                   name="shoe_size" bind:value={profileDetails.size_shoe}/>
                                            <span>{profileDetails.size_shoe}</span>
                                        </div>
                                    </div>
                                    <InputText bind:value={profileDetails.size_dress}
                                               name="size_dress"
                                               css="w-64 ml-[3px]"
                                               label="Confectiemaat"
                                               type="number"
                                               error="{detailsErrorFields.size_dress}"
                                               required="true"></InputText>
                                </div>
                                <div class="mb-5 pb-10 border-b border-gray-200">
                                    <SubscriberTypes label="Kies jouw type rollen" bind:error={detailsErrorFields.types}
                                                     knownTypes="{$page.props.subscriberTypes}"
                                                     bind:subscriberTypes={profileDetails.types}/>
                                </div>
                                <div>
                                    <div class="label">Voeg nu nog wat foto's toe aan je profiel!</div>
                                    <ProfileImages bind:error={detailsErrorFields.images} bind:photos="{profileDetails.images}" />
                                    {#if uploading}
                                        <span class="loading loading-spinner loading-md"></span>
                                    {/if}
                                </div>
                            </div>

                        </div>
                    </div>

                    <button class="btn mt-5 text-white btn-primary" on:click={proceed}>Opslaan</button>
                </div>
            </div>
        {/if}

    </div>
{/if}
