<script context="module">
    import "dayjs/esm/locale/nl";
    import dayjs from "dayjs/esm";
  
    dayjs.locale("nl"); // Dutch locale

    
  </script>
  
  <script>
    import Time from "svelte-time";
    export let timestamp;
    export let format;

  </script>
  
  <Time timestamp={timestamp} format={format} />