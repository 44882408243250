
<script>
    export let label;
    export let name;
    export let style = "primary";

    $: props = (({label, name, style, ...rest }) => rest)($$props);
</script>

    <input type="button" name="{name}" value="{label}" class="btn btn-{style}">
