<script>
    import {router, Link, page} from '@inertiajs/svelte'
    import {fade} from 'svelte/transition';
    import {loadingState} from '@/Utils/stores';
    import Fa from 'svelte-fa/src/fa.svelte'
    import NotifyModal from './NotifyModal.svelte';
    import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
    import {route} from "@/Utils";

    $: props = (({...rest}) => rest)($$props);

    let loading;
    let showLogoutConfirm = false;
    loadingState.subscribe(state => {
        loading = state;
    })

    function onMount() {
    }

    function showLogoutConfirmF() {
        showLogoutConfirm = true
    }

    function handleNotifyModal(e) {
        if (e.detail.text == "cancel") {
            showLogoutConfirm = false;
        } else if (e.detail.text == "confirm") {
            router.visit('/logout');
        }
    }
</script>


<div class="navbar bg-base-100 shadow-xl rounded-box box-border" in:fade>
    <div class="navbar-start">

        <div class="dropdown z-50">
            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label tabindex="0" class="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16"/>
                </svg>
            </label>
            <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
            <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                <li>
                    <Link href="/profiel" only={['show']} class={$page.url === '/profiel' ? 'active' : ''}>Mijn
                        Profiel
                    </Link>
                </li>
                {#if $page.props?.paymentStatus === 'paid'}
                    <li>
                        <Link href="{route('rollen')}" only={['show']} class={$page.url === '/rollen' ? 'active' : ''}>
                            Beschikbare Rollen
                        </Link>
                    </li>
                    <li>
                        <Link href="{route('gespeelde-rollen')}" only={['show']}
                              class={$page.url === '/gespeelde-rollen' ? 'active' : ''}>Gespeelde Rollen
                        </Link>
                    </li>
                {:else if $page.props?.paymentStatus !== 'paid'}
                    <li class="cursor-not-allowed"><span
                            class="text-gray-400 cursor-not-allowed">Beschikbare Rollen</span></li>
                    <li><span class="text-gray-400 cursor-not-allowed">Gespeelde Rollen</span></li>
                {/if}
            </ul>
        </div>

        <Link href="/"><img src="/img/logo-normal.png" alt="logo" class="h-10 ml-4"></Link>

    </div>

    <div class="navbar-center hidden lg:flex">
        <ul class="menu menu-horizontal px-1 gap-2">
            <li>
                <Link href="/profiel" only={['show']} class={$page.url === '/profiel' ? 'active' : ''}>Mijn Profiel
                </Link>
            </li>
            {#if $page.props?.paymentStatus === 'paid'}
                <li>
                    <Link only="{['show']}" replace="{true}" href="{route('rollen')}"
                          class={$page.url === '/rollen' ? 'active' : ''}>Beschikbare Rollen
                    </Link>
                <li>
                    <Link only="{['show']}" replace="{true}" href="{route('gespeelde-rollen')}"
                          class={$page.url === '/gespeelde-rollen' ? 'active' : ''}>Gespeelde Rollen
                    </Link>
                </li>
            {:else if $page.props?.paymentStatus !== 'paid'}
                <li><span class="text-gray-400 !cursor-not-allowed {$page.url === '/rollen' ? 'active' : ''}">Beschikbare Rollen</span>
                </li>
                <li><span class="text-gray-400 !cursor-not-allowed {$page.url === '/gespeelde-rollen' ? 'active' : ''}">Gespeelde Rollen</span>
                </li>
            {/if}
        </ul>
    </div>
    <div class="navbar-end">
        <button class=" mr-4" on:click={showLogoutConfirmF} name="Loguit" alt="loguit">
            <Fa icon={faRightFromBracket}/>
        </button>
        <span class="checkbox checkbox-primary mx-4 h-5 w-5 {loading === 'success' ? 'success' : 'hidden'}"
              aria-checked="true"></span>
        <svg class="mx-4 fill-accent {loading === 'loading' ? 'loading' : 'hidden'}" height="16px" width="16px"
             version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.319 235.319"
             xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 235.319 235.319">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="m201.094,29.997c2.649-0.623 4.623-2.996 4.623-5.835v-18.162c0-3.313-2.687-6-6-6h-164.114c-3.313,0-6,2.687-6,6v18.163c0,2.839 1.974,5.212 4.623,5.835 1.812,32.314 18.594,61.928 45.682,80.076l11.324,7.586-11.324,7.586c-27.089,18.147-43.871,47.762-45.682,80.076-2.649,0.623-4.623,2.996-4.623,5.835v18.163c0,3.313 2.687,6 6,6h164.114c3.313,0 6-2.687 6-6v-18.163c0-2.839-1.974-5.212-4.623-5.835-1.812-32.314-18.594-61.928-45.683-80.076l-11.324-7.586 11.324-7.586c27.089-18.148 43.871-47.763 45.683-80.077zm-159.491-17.997h152.114v6.163h-152.114v-6.163zm152.114,211.319h-152.114v-6.163h152.114v6.163zm-63.749-110.644c-1.663,1.114-2.661,2.983-2.661,4.985s0.998,3.871 2.661,4.985l18.765,12.571c23.71,15.883 38.49,41.705 40.333,69.941h-142.812c1.843-28.235 16.623-54.057 40.333-69.941l18.765-12.571c1.663-1.114 2.661-2.983 2.661-4.985s-0.998-3.871-2.661-4.985l-18.765-12.571c-23.71-15.884-38.49-41.706-40.333-69.941h142.812c-1.843,28.236-16.623,54.057-40.333,69.941l-18.765,12.571z"></path>
                    <path d="m133.307,82.66h-31.295c-2.487,0-4.717,1.535-5.605,3.858-0.888,2.324-0.25,4.955 1.604,6.613l15.647,14c1.139,1.019 2.57,1.528 4,1.528s2.862-0.509 4-1.528l15.647-14c1.854-1.659 2.492-4.29 1.604-6.613-0.885-2.323-3.115-3.858-5.602-3.858z"></path>
                    <path d="m117.414,140.581l-15.218,9.775c-13.306,8.914-21.292,23.876-21.292,39.892h76.511c0-16.016-7.986-30.978-21.292-39.892l-15.218-9.775c-1.074-0.644-2.416-0.644-3.491,0z"></path>
                </g>
            </g>
        </svg>
    </div>
</div>


<NotifyModal on:message={handleNotifyModal}
             cancelText={"Laat maar..."}
             confirmText={"Inderdaad!"}
             title={"Hoi!"}
             text={"Wil je echt uitloggen?"}
             checked={showLogoutConfirm}/>


<style>
    .loading {
        animation: loader 1s infinite;
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }

    .success {
        animation-name: bounce;
        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
        animation-duration: 1s;
    }


    @keyframes bounce {
        0% {
            transform: scale(1, 1) translateY(0);
            opacity: 0;
        }
        10% {
            transform: scale(1.3, .7) translateY(0);
            opacity: 1;
        }
        30% {
            transform: scale(.7, 1.3) translateY(-16px);
        }
        50% {
            transform: scale(1.05, .95) translateY(0);
        }
        57% {
            transform: scale(1, 1) translateY(-3px);
            opacity: 1;
        }
        64% {
            transform: scale(1, 1) translateY(0);
        }
        100% {
            transform: scale(1, 1) translateY(0);
            opacity: 0;
        }
    }
</style>
