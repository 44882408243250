<script>
    export let label;
    export let name;
    export let options = []; // Array of objects {name: "display name", value: "actual value"}
    export let value;
    export let error;
    export let css = '';
    export let required = false;

    $: props = (({label, name, error, ...rest}) => rest)($$props);

    function update(event) {
        value = event.target.value
    }
</script>

<div class="form-control flex-1">
    {#if label}
        <label class="label justify-start gap-2" for="{name}">
            <span class="label-text">{label}</span>
            {#if required}
                <span class="text-error">*</span>
            {/if}
        </label>
    {/if}
    <div class="w-full relative">
        <select name="{name}" id="{name}" bind:value={value} class="input w-full input-bordered {error ? 'input-error' : ''} {css}" on:change={update}>
            <option value="" disabled="disabled" selected="selected">Maak een keuze</option>
            {#each options as option}
                <option value={option.value}>{option.name}</option>
            {/each}
        </select>
    </div>
</div>
