<script>
    import Fa from "svelte-fa";
    import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

    export let title;
    export let message;
    export let onConfirm;
    export let onCancel;

    function handleConfirm() {
        onConfirm();
    }

    function handleCancel() {
        onCancel();
    }
</script>

<dialog class="modal" id="confirm_lock_modal">
    <div class="modal-box">
        <h3 class="font-bold text-lg">{title}</h3>
        <p class="py-4">{message}</p>
        <div class="modal-action">
            <button class="btn text-white btn-primary" on:click={handleConfirm}><Fa icon="{faCheck}" /> Ja</button>
            <button class="btn btn-danger" on:click={handleCancel}><Fa icon="{faXmark}" /> Nee</button>
        </div>
    </div>
</dialog>
