<script>
    import Navbar from '../Elements/Navbar.svelte';
    import { fly } from 'svelte/transition';
    import { cookieConsent } from '../Utils/stores';

    let checked = $cookieConsent != "set";
    function setConsent(){
        checked = false;
        cookieConsent.set("set");
    }
</script>

<svelte:head>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg" />
    <title>B&F Casting</title>
    <meta name="robots" content="noindex nofollow" />
</svelte:head>

<Navbar />

<div class="flex justify-center pt-8">
    <slot />
</div>

{#if checked}
<input type="checkbox" id="consent-modal" class="modal-toggle" {checked} />
<div class="modal modal-bottom">
  <div class="modal-box w-5/6" aria-roledescription="CookiePopUp" in:fly="{{ y: 200, duration: 200, delay: 100 }}" out:fly="{{ y: 200, duration: 200, delay: 100 }}" on:mouseenter={setConsent}>
    <h3 class="font-bold text-lg">Cookies!</h3>
    <p class="py-4">We gebruiken alleen functionele cookies dus je hoeft mij niet weg te klikken. Ik ga al!</p>
    <div class="modal-action">
      <label for="consent-modal" class="btn">Yay!</label>
    </div>
  </div>
</div>
{/if}
