<script>
    import {fade} from "svelte/transition";
</script>

<svelte:head>
    <title>B&F Casting | Selectie Fout</title>
    <meta name="robots" content="noindex nofollow"/>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg"/>
</svelte:head>

<div class="bg-gradient-to-br" in:fade>
    <div class="alert alert-error">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             class="stroke-current shrink-0 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span>De opgegeven selectie is niet gevonden of bevat geen figuranten.</span>
    </div>
</div>
