<script context="module">
    // export {default as layout} from "../Layout/Layout.svelte";
</script>

<script>
    import {onMount} from "svelte";
    import {page} from "@inertiajs/svelte";

    import {route} from "../Utils";
    import ConfirmModal from '../Components/ConfirmModal.svelte';

    //effects
    import {crossfade, fade, slide} from "svelte/transition";
    import SubscriberComponent from "../Components/SelectionSubscriberComponent.svelte";
    import {quintOut} from "svelte/easing";
    import {flip} from 'svelte/animate';
    import {faFileDownload, faLock} from "@fortawesome/free-solid-svg-icons";
    import Fa from "svelte-fa";

    export let selection = $page.props.selection;
    export let job = $page.props.selection.job;
    export let roles = $page.props.selection.roles;
    export let selectionLevel = selection.level === 'selection' ? 'Selectie' : 'Voorselectie'
    export let selectionType = undefined;
    export let eyecolors = $page.props.eyecolors;
    export let haircolors = $page.props.haircolors;
    export let ethnicities = $page.props.ethnicities;

    let currentRoleId;

    let updateError = "";
    let roleSubscribers = [];

    // The selection is always either a role or a job
    let isRole = false;
    let isSelection = false;
    let properties = [];

    let confirmLockModal;

    onMount(() => {
        selectionType = selection.selectionable_type.split('\\').slice(-1)[0].toLowerCase();
        isRole = selectionType === 'role';
        isSelection = selection.level === 'selection';

        // If the selection is a role, we need to get the subscribers from the role so we can filter them
        if (isRole && !isSelection) {
            roleSubscribers = roles[0].subscribers;
        } else {
            roleSubscribers = [];
        }

        confirmLockModal = document.getElementById('confirm_lock_modal');
    });

    $: setProperties();
    $: selectedSubscribers = roleSubscribers.filter((s) => inSelection(s));
    $: preselectedSubscribers = roleSubscribers.filter((s) => inPreSelection(s));


    function setProperties() {
        for (let settingsKey in selection.settings) {
            if (selection.settings[settingsKey]) {
                properties.push(settingsKey.replace('show_', ''));
            }
        }
    }

    function inPreSelection(s) {
        return s.pivot.in_preselection && !s.pivot.in_selection;
    }

    function inSelection(s) {
        return s.pivot.in_selection && s.pivot.in_selection;
    }

    function toSelection(s) {
        updateSubscriber(s, true);
    }

    function toPreselection(s) {
        updateSubscriber(s, false);
    }

    const accessorObject = {
        toSelection: toSelection,
        toPreselection: toPreselection,
    }

    function openModal(roleId) {
        currentRoleId = roleId;
        confirmLockModal.showModal();
    }

    function confirmLock() {
        lockRole(currentRoleId);
        confirmLockModal.close();
    }

    function closeLockModal() {
        confirmLockModal.close();
    }

    const [send, receive] = crossfade({
        fallback(node, params) {
            const style = getComputedStyle(node);
            const transform = style.transform === 'none' ? '' : style.transform;

            return {
                duration: 300,
                easing: quintOut,
                css: (t) => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
            };
        }
    });

    function updateSubscriber(subscriber, inSelection) {
        let response;

        if (inSelection) {
            response = fetch(`/api/selection/${selection.id}/subscriber/${subscriber.id}`, {
                method: 'PATCH',
            })
        } else {
            response = fetch(`/api/selection/${selection.id}/subscriber/${subscriber.id}`, {
                method: 'DELETE'
            })
        }


        response.then((res) => {
            if (res.ok) {
                subscriber.pivot.in_selection = inSelection;
                roleSubscribers = roleSubscribers;
                updateError = "";
                return;
            }

            if (res.status === 400) {
                updateError = `Deze selectie is vergrendeld, je kan deze niet meer aanpassen.`;
                return;
            }

            throw new Error(res.status);
        }).catch((err) => {
            updateError = `Er is iets misgegaan, probeer het later opnieuw (foutcode: ${err}).`;
        })
    }

    async function lockRole(roleId) {
        try {
            const response = await fetch(`/api/role/${roleId}/_lock`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            for (let i = 0; i < roles.length; i++) {
                if (roles[i].id === roleId) {
                    roles[i].selection_islocked = 1;
                }
            }

        } catch (error) {
            console.error('Failed to lock role:', error);
        }
    }

</script>

<svelte:head>
    <title>B&F Casting - {selectionLevel} - {job?.name} </title>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg" />
    <meta name="robots" content="noindex nofollow"/>
</svelte:head>

<style>
    td {
        word-break: break-word;
    }
</style>

<div class="card w-full bg-base-100 min-h-screen md:p-10 p-2" in:fade>

    {#if (!isRole && selection.level === 'preselection')}
        <div class="alert alert-info">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 class="stroke-current shrink-0 w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>Het aanpassen van een <em>Voorselectie</em> op opdracht niveau is niet ondersteund.</span>
        </div>
    {:else}

        {#if updateError !== ''}
            <div class="alert alert-error mb-5" in:slide>
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                     viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span>{updateError}</span>
            </div>
        {/if}

        <div class="card-title flex flex-col md:flex-row justify-between items-center">
            <h1 class="text-xl">{selectionLevel} - {job?.name}</h1>
            {#if selection.level === 'selection'}
                <div>
                    <a class="btn btn-primary text-white" href="{route('selection.pdf', selection.slug)}" download>
                        <Fa icon="{faFileDownload}"></Fa>
                        Exporteer naar PDF</a>
                </div>
            {/if}
            {#if selection.level === 'preselection' && !isSelection}
                {#if selection.roles[0].selection_islocked === 1}
                    <button
                        disabled="disabled"
                        class="btn btn-accent">
                        <Fa icon="{faLock}"></Fa>
                        Selectie vastgelegd
                    </button>
                {:else}
                    <button
                        on:click={() => openModal(selection.roles[0].id)}
                        class="btn btn-accent">
                        <Fa icon="{faLock}"></Fa>
                        Selectie vastleggen
                    </button>
                {/if}
            {/if}
        </div>
        <div class="card-body p-1">
            <div class="selection">
                {#if selection.level === 'preselection'}
                    <div class="pm-5 text-neutral-500">
                        Maak aanpassing in de selectie door de onderstaande figuranten in of uit de selectie te
                        plaatsen.
                        Wijzigingen op deze pagina worden automatisch opgeslagen.
                    </div>
                {/if}

                {#if (!isSelection)}
                    <div class="role">
                        {#if selection.level === 'selection'}
                            {#if selection.roles[0].selection_islocked === 1}
                                <button
                                    disabled="disabled"
                                    class="btn btn-primary text-white float-right">
                                    <Fa icon="{faLock}"></Fa>
                                    Selectie vastleggen
                                </button>
                            {:else}
                                <button
                                    on:click={openModal(selection.roles[0].id)}
                                    class="btn btn-primary text-white float-right">
                                    <Fa icon="{faLock}"></Fa>
                                    Selectie vastleggen
                                </button>
                            {/if}
                        {/if}
                        <div class="divider"></div>
                        <div class="flex flex-row justify-center font-bold">
                            <span>{selection.roles[0].name}</span>
                            {#if selection.roles[0].selection_islocked === 1 }
                                (Vastgelegd)
                            {/if}
                        </div>
                    </div>
                    <div class="role-preselection">
                        <div class="hidden md:flex flex-row justify-between mb-5">
                            <em>Voorselectie ({preselectedSubscribers.length})</em>
                            <em>Geselecteerd ({selectedSubscribers.length})</em>
                        </div>
                        <div class="subscribers grid gap-3 sm:gap-10 sm:grid-cols-2 grid-cols-1">
                            <em class="text-center md:hidden">Voorselectie ({preselectedSubscribers.length})</em>
                            <div class="pre-selection">
                                {#each preselectedSubscribers as subscriber (subscriber.id)}
                                    <div in:receive={{ key: subscriber.id }} out:send={{ key: subscriber.id }}
                                         animate:flip={{duration: 400}}>
                                        <SubscriberComponent accessorObject="{accessorObject}"
                                                             subscriber="{subscriber}"
                                                             properties="{properties}"
                                                             haircolors="{haircolors}"
                                                             eyecolors="{eyecolors}"
                                                             editable="{selection.level === 'preselection' && roles[0].selection_islocked === 0}"
                                                             ethnicities="{ethnicities}"/>
                                    </div>
                                {/each}
                            </div>
                            <div class="divider md:hidden"><em>Geselecteerd ({selectedSubscribers.length})</em></div>
                            <div class="selection">
                                {#each selectedSubscribers as subscriber (subscriber.id)}
                                    <div in:receive={{ key: subscriber.id }} out:send={{ key: subscriber.id }}
                                         animate:flip={{duration: 400}}>
                                        <SubscriberComponent accessorObject="{accessorObject}"
                                                             subscriber="{subscriber}"
                                                             properties="{properties}"
                                                             haircolors="{haircolors}"
                                                             eyecolors="{eyecolors}"
                                                             editable="{selection.level === 'preselection' && roles[0].selection_islocked === 0}"
                                                             ethnicities="{ethnicities}"/>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </div>

                {:else}
                    {#each roles as role (role.id)}
                        <div class="role">
                            <div class="font-bold items-center text-center mb-5 flex flex-wrap flex-col">
                                <div class="divider"></div>
                                <span>{role.name}
                                {#if role.selection_islocked === 1 }
                                    (Vastgelegd)
                                {/if}
                                </span>

                                {#if selection.level === 'selection'}
                                    {#if role.selection_islocked !== 1}
                                        <button
                                            on:click={ openModal(role.id)}
                                            class="btn btn-accent mt-2 btn-sm text-white">
                                            <Fa icon="{faLock}"></Fa>
                                            Selectie vastleggen
                                        </button>
                                    {/if}
                                {/if}
                            </div>
                        </div>
                        <div class="subscribers grid gap-10 grid-cols-1 m-auto md:max-w-[75%]">
                            <div class="selection">
                                {#if role.subscribers.length === 0 }
                                    <div class="alert mt-5 mb-5 md:m-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             class="stroke-info shrink-0 w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                        <span>Er zijn nog geen figuranten geselecteerd voor deze rol.</span>
                                    </div>
                                {/if}
                                {#each role.subscribers as subscriber (subscriber.id)}
                                    <div>
                                        <SubscriberComponent accessorObject="{accessorObject}"
                                                             subscriber="{subscriber}"
                                                             properties="{properties}"
                                                             haircolors="{haircolors}"
                                                             eyecolors="{eyecolors}"
                                                             editable="{false}"
                                                             ethnicities="{ethnicities}"/>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    {/each}
                {/if}
            </div>
            <div class="card-actions justify-end">
                {#if selection.level === 'preselection' && !isSelection}
                    {#if selection.roles[0].selection_islocked === 1}
                        <button
                                disabled="disabled"
                                class="btn btn-accent">
                            <Fa icon="{faLock}"></Fa>
                            Selectie vastgelegd
                        </button>
                    {:else}
                        <button
                                data-tip="Leg de selectie keuze vast"
                                on:click={() => openModal(selection.roles[0].id)}
                                class="btn btn-accent">
                            <Fa icon="{faLock}"></Fa>
                            Selectie vastleggen
                        </button>
                    {/if}
                {/if}
            </div>
        </div>
    {/if}
    <ConfirmModal
        id="confirm_lock_modal"
        title="Selectie vastleggen"
        message="Weet je zeker dat je de selectie wil vastleggen? Na het vastleggen is de selectie definitief. Dit kan niet meer ongedaan gemaakt worden."
        onConfirm={confirmLock}
        onCancel={closeLockModal}/>
</div>

