<script>
	import { createEventDispatcher, onDestroy } from 'svelte';
    import { fade, fly } from 'svelte/transition';

    export let title = "Rol";
    export let text = 'Heb je de juiste gegevens ingevuld?';
	export let cancelText = "Neeeeeee!"
	export let confirmText = "Inschrijven"
	export let checked = false;
	export let disabled = false;
	export let reason = false;


	const dispatch = createEventDispatcher();

	let modal;

	disabled = checked;

	if(disabled === true){
		confirmText = "Ingeschreven";
	}

	function cancel() {
		dispatch('message', {
			text: 'cancel'
		});
	}

	function confirm() {
		dispatch('message', {
			text: 'confirm'
		});
	}




	const handle_keydown = e => {
		if (e.key === 'Escape') {
			cancel();
			return;
		}

		if (e.key === 'Tab') {
			// trap focus
			const nodes = modal.querySelectorAll('*');
			const tabbable = Array.from(nodes).filter(n => n.tabIndex >= 0);

			let index = tabbable.indexOf(document.activeElement);
			if (index === -1 && e.shiftKey) index = 0;

			index += tabbable.length + (e.shiftKey ? -1 : 1);
			index %= tabbable.length;

			tabbable[index].focus();
			e.preventDefault();
		}
	};

	const previously_focused = typeof document !== 'undefined' && document.activeElement;

	if (previously_focused) {
		onDestroy(() => {
			previously_focused.focus();
		});
	}

    $: props = (({title, error, ...rest }) => rest)($$props);
</script>

<svelte:window on:keydown={handle_keydown} />

<!-- Put this part before </body> tag -->
<input type="checkbox" id="notify-modal" class="modal-toggle" bind:checked />
<div class="modal modal-middle" in:fade="{{duration: 200}}" out:fade="{{duration: 200}}">
  <div class="modal-box w-10/12 max-w-5xl" in:fly="{{ y: 200, duration: 200, delay: 100 }}">
    <h2 class="font-bold text-lg">{title}</h2>

    <p class="py-4 whitespace-pre-line">{text}</p>
	{#if reason}
	<p><i><b>* {reason}</b></i></p>
	{/if}
    <div class="modal-action">
		{#if confirmText}
			<button for="notify-modal" class="btn btn-accent" {disabled} on:click={confirm}>{confirmText}</button>
		{/if}
		{#if cancelText}
      		<button for="notify-modal" class="btn btn-outline btn-info" on:click={cancel}>Annuleer</button>
		{/if}

    </div>
  </div>
</div>
