<script>
    import {Link} from '@inertiajs/svelte'
    import {onMount} from "svelte";

    export let photos;
    export let checked;
    export let selected;

    const handle_keydown = e => {
        if (e.key === 'Escape') {
            checked = false;
        }
    }

    $: if (selected) {
        window.location.href = `#photo:${selected}`;
    }

    function close() {
        checked = false;
    }

    function selectPhoto(e) {
        selected = e;
        window.location.href = `#photo:${selected}`;
    }

</script>
<svelte:window on:keydown={handle_keydown}/>
<input type="checkbox" id="img-modal" class="modal-toggle" {checked}/>
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div for="img-modal" class="modal cursor-pointer">

    <div class="modal-box w-11/12 max-w-full">
        <button class="btn-sm btn-circle btn-outline btn-error absolute right-1 top-1" on:click={close}>✕</button>
        <div class="carousel carousel-center p-4 space-x-4 bg-neutral rounded-box" style="max-height: 80vh;">

            {#each photos as photo,i}
                <div id="photo:{photo.id}" class="carousel-item w-11/12 rounded-box">
                    <img class="object-contain h-full w-full"
                         loading="lazy"
                         src="https://cma.benfcasting.nl/photos/images/subscriber/original{photo.path}"
                         alt="profielfoto"
                         data-id="{photo.id}">
                </div>
            {/each}

        </div>
        <div class="flex justify-center w-full py-2 gap-2">
            {#key window.location.href.endsWith(`#photo:${selected}`)}
                {#each photos as photo,i}
                    <button on:click={() => selectPhoto(photo.id)} type="button" class="btn btn-xs {photo.id === selected ? 'btn-accent' : ''}">{i + 1}</button>
                {/each}
            {/key}
        </div>

    </div>
</div>
