<script>
    import Fa from "svelte-fa/src/fa.svelte";
    import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

    export let label;
    export let placeholder;
    export let name;
    export let type = 'text';
    export let value;
    export let error;
    export let css = '';
    export let cssContainer = '';
    export let required = false;
    export let peek = false;
    export let disabled = false;

    $: props = (({label, name, type, error, ...rest}) => rest)($$props);

    function update(event) {
        value = event.target.value
    }
</script>


<div class="form-control flex-1 {cssContainer}">
    {#if label}
        <label class="label justify-start gap-2" for="{name}">
            <span class="label-text">{label}</span>
            {#if required}
                <span class="text-error">*</span>
            {/if}
        </label>
    {/if}
    <div class="w-full relative">
        <input disabled="{disabled}" type="{type}" name="{name}" id="{name}" placeholder="{placeholder}" {value}
               class="input w-full input-bordered {error ? 'input-error' : ''} {css}" on:input={update}>
        {#if peek}
            <button type="button" class="absolute right-0 btn btn-square btn-ghost " on:click={() => type = type === "password" ? "text" : "password"}>
                {#if (type === "password")}
                    <Fa icon={faEyeSlash} class="text-gray-400"/>
                {:else}
                    <Fa icon={faEye} class="text-gray-400"/>
                {/if}
            </button>
        {/if}
    </div>
</div>
