<script context="module">
    export {default as layout} from "../Layout/Layout.svelte";
</script>

<script>
    //elements
    import VideoPlayer from "../Elements/VideoPlayer.svelte";
    import Lazy from "svelte-lazy";
    import Dropzone from "../Elements/Dropzone.svelte";
    import {SortableList} from "@jhubbardsf/svelte-sortablejs";
    import InputText from "../Elements/InputText.svelte";

    //stores
    import {page, router, useForm} from "@inertiajs/svelte";
    import {dbupdp, loadingState} from "@/Utils/stores";

    //effects
    import {fade, scale} from "svelte/transition";

    //modals
    import ImgModal from "../Elements/ImgModal.svelte";

    //icons
    import Fa from "svelte-fa/src/fa.svelte";
    import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";

    // includes
    import {faTrash} from "@fortawesome/free-solid-svg-icons";
    import axios from "axios";
    import PaymentStatus from "../Components/PaymentStatus.svelte";
    import ProfileDataMissing from "../Components/ProfileDataMissing.svelte";
    import SubscriberTypes from "../Elements/SubscriberTypes.svelte";
    import {FILE_TOO_LARGE} from "@/Utils/dropzone";

    const route = window.route;
    let profileModel;

    export let show;
    export let errors;
    export let photos;

    dbupdp.set($page.props.subscriber.upddbp);

    const hasPaid = () => $page.props.paymentStatus === "paid";

    //booleans
    let showPersonaliaModal = false;
    let showBodyAttrModal = false;
    let showAboutModal = false;
    let showVideoModal = false;

    let disabled = false;
    let loading = false;

    let errorTexts = {
        personal: "",
        body: "",
        bio: "",
        video: "",
        images: ""
    };

    // Profile values
    let ethnicity = $page.props.subscriber?.ethnicity?.name ?? '';
    let eyeColor = $page.props.subscriber?.eye_color?.name ?? '';
    let hairColor = $page.props.subscriber?.hair_color?.name ?? '';
    $: selectedSex = $subscriber.sex.toUpperCase();
    $: profilePicPath = photos[0]?.path ?? '';

    //formhelper
    let user = useForm($page.props.user);
    let subscriber = useForm($page.props.subscriber);

    let bd = new Date($subscriber.birthdate);

    let showPhotoModal = false;
    let photoToShow = 0;

    let loadingImages = false;
    let files = {
        accepted: [],
        rejected: [],
    };

    function handleFilesSelect(e) {
        const {acceptedFiles, fileRejections} = e.detail;
        files.accepted = [...files.accepted, ...acceptedFiles];
        files.rejected = [...files.rejected, ...fileRejections];

        errorTexts.images = "";

        if (files.rejected.length > 0) {
            errorTexts.images = "Er zijn bestanden afgewezen, controleer of de bestanden voldoen aan de eisen:";
            for (const rejected of files.rejected) {
                console.log(rejected);
                if (rejected.errors[0].code === FILE_TOO_LARGE) {
                    errorTexts.images += "<br> - '" + rejected.file.name + "' is te groot, max 2MB";
                }
            }
        }

        if (files.accepted.length === 0) {
            return;
        }

        loadingImages = true;

        const uploadPromises = files.accepted.map((file) => {
            return axios.post('/profiel/images/', {file: file}, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        })

        Promise.all(uploadPromises).then(() => {
            files.accepted = [];
            files.rejected = [];
            loadingImages = false;
            refresh();
        }).catch((e) => {
            errorTexts.images = e;
        });
    }

    function refresh() {
        router.reload(true);
    }

    function updatePhotoOrder(e) {
        photos.splice(e.newIndex, 0, photos.splice(e.oldIndex, 1)[0]);
        profilePicPath = photos[0]?.path ?? '';

        if (photos.length < 1) {
            return;
        }

        axios.post(route("profiel.images.order"), {ids: photos.map((p) => p.id)}).then(() => {
        });
    }

    function deletePhoto(id) {
        photos = photos.filter((p) => p.id !== id);
        window.axios.delete('/profiel/images/' + id).then(() => {
            refresh();
        })
    }

    function cancelEdit() {
        loadingState.set(false);
        $user.reset();
        $user.clearErrors();
        $subscriber.reset();
        $subscriber.clearErrors();
        showPersonaliaModal = false;
        showBodyAttrModal = false;
        showAboutModal = false;
        showVideoModal = false;

        disabled = false;
    }

    function saveEdit(type = '') {
        disabled = true;
        //save user object
        if ($user.isDirty) {
            $user.patch(route("profiel.patch", {type: "user"}), {
                onSuccess: () => {
                    $user.defaults();
                    if (!$subscriber.isDirty) {
                        errorTexts.personal = "";
                        showPersonaliaModal = false;
                        showBodyAttrModal = false;
                        showAboutModal = false;
                        disabled = false;
                        dbupdp.set("true");
                    }
                },
                onError: (e) => {
                    errorTexts.personal = Object.values(e)[0];
                    disabled = false;
                },
            });
        }

        //save subscriber object
        if ($subscriber.isDirty) {
            $subscriber.patch(route("profiel.patch", {type: `subscriber|${type}`}), {
                onSuccess: () => {
                    $subscriber.defaults();
                    if (!$user.isDirty) {
                        errorTexts.personal = "";
                        errorTexts.body = "";
                        errorTexts.bio = "";
                        errorTexts.video = "";
                        showPersonaliaModal = false;
                        showBodyAttrModal = false;
                        showAboutModal = false;
                        showVideoModal = false;
                        ethnicity = $page.props.subscriber?.ethnicity?.name ?? '';
                        eyeColor = $page.props.subscriber?.eye_color?.name ?? '';
                        hairColor = $page.props.subscriber?.hair_color?.name ?? '';
                        disabled = false;
                        dbupdp.set("true");
                    }
                },
                onError: (e) => {
                    switch (type) {
                        case 'body-details':
                            errorTexts.body = Object.values(e)[0];
                            break;
                        case 'bio':
                            errorTexts.bio = Object.values(e)[0];
                            break;
                        case 'video':
                            errorTexts.video = Object.values(e)[0];
                            break;
                        case 'personal':
                            errorTexts.personal = Object.values(e)[0];
                            break;
                        default:
                            break;
                    }

                    disabled = false;
                },
            });
        }
    }

    function showPhoto(p) {
        photoToShow = p;
        showPhotoModal = true;
    }

    function getMissingData(type = '') {
        const user = $page.props.user;
        const subscriber = $page.props.subscriber;

        if (type === 'personal' && (!user.firstname || !user.lastname || !user.email || !subscriber.street ||
            !subscriber.street_number || !subscriber.zipcode || !subscriber.city || !subscriber.phone_mobile ||
            !subscriber.sex || !subscriber.birthdate || !subscriber.bsn || !subscriber.iban)) {
            return true;
        }

        if (type === 'details' && (!subscriber.ethnicity_id || !subscriber.hair_color_id || !subscriber.eye_color_id ||
            !subscriber.height || !subscriber.weight || !subscriber.size_shoe || !subscriber.size_dress)) {
            return true;
        }

        return false;
    }
</script>

<svelte:head>
    <title>B&F Casting | Profiel</title>
    <meta name="robots" content="noindex nofollow"/>
</svelte:head>

<!--Main -->

<div
    id="profile-main-content"
    class="bg-gradient-to-br"
    in:fade={{ delay: 500 }}
    out:fade={{duration: 50}}
>

    <div class="error w-full">
        <PaymentStatus paymentStatus={$page.props.paymentStatus} justPaid={$page.props.justPaid}/>
    </div>
    <div class="profile-errors mb-5 mt-5">
        <ProfileDataMissing user="{$page.props.user}" subscriber="{$page.props.subscriber}"/>
    </div>

    <div class="w-full grid grid-cols-1 justify-center gap-4 lg:grid-cols-8">
        <div class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <h2 class="card-title">Profielfoto</h2>
                <div class="avatar">
                    <div class="w-full">
                        {#if profilePicPath}
                            <img
                                class="rounded shadow-md h-56"
                                src="https://cma.benfcasting.nl/photos/images/subscriber/thumb{profilePicPath}"
                                alt="profiel foto"
                            />
                        {:else }
                            <em>Voeg een foto toe in het foto's vak hieronder!</em>
                        {/if}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="card w-full bg-base-100 shadow-xl lg:col-span-2 {getMissingData('personal') ? 'border-4 border-error' : ''}">
            <div class="card-body">
                <div class="h-full">
                    <h2 class="card-title">Personalia</h2>
                    <ul class="list-none">
                        <li><strong>Naam: </strong>
                            {[
                                $user.firstname,
                                $user.preposition,
                                $user.lastname,
                            ].join(" ")}
                        </li>
                        <li>
                            <strong>Leeftijd:</strong> {$subscriber.age} ({$subscriber.birthdate.substring(0, 10)})
                        </li>
                        <li>
                            <strong>Geslacht:</strong> {selectedSex === 'M' ? 'Man' : selectedSex === 'F' ? 'Vrouw' : 'Anders'}
                        </li>
                        <li><strong>Email:</strong> {$user.email}</li>
                        <li><strong>Telefoon:</strong> {$subscriber.phone_mobile}</li>
                        <li>
                            <strong>BSN:</strong>
                            <div class="badge badge-outline">verborgen</div>
                        </li>
                        <li>
                            <strong>IBAN:</strong>
                            <div class="badge badge-outline">verborgen</div>
                        </li>
                        <li>
                            <strong>Straat:</strong> {[
                            $subscriber.street,
                            $subscriber.street_number,
                            $subscriber.street_number_add,
                        ].join(" ")}
                        </li>
                        <li><strong>PC/Plaats:</strong> {$subscriber.zipcode} {$subscriber.city}</li>
                    </ul>
                </div>
                <div class="card-actions justify-end">
                    <button
                        disabled={!hasPaid()}
                        class="btn btn-primary"
                        on:click={() => {
                        showPersonaliaModal = true;
                    }}>
                        <Fa icon={faPenToSquare}/>
                    </button
                    >
                </div>
            </div>
        </div>

        <div
            class="card w-full bg-base-100 shadow-xl lg:col-span-2 {getMissingData('details') ? 'border-4 border-error' : ''}">
            <div class="card-body">
                <h2 class="card-title">Lichaamskenmerken</h2>
                <div class="h-full">
                    <ul class="list-none">
                        <li><strong>Etniciteit:</strong> {(!ethnicity) ? '' : ethnicity}</li>
                        <li><strong>Oogkleur:</strong> {(!eyeColor) ? '' : eyeColor}</li>
                        <li><strong>Haarkleur:</strong> {(!hairColor) ? '' : hairColor}</li>
                        <li>
                            <strong>Lengte:</strong>
                            {($subscriber?.height)} cm
                        </li>
                        <li><strong>Gewicht:</strong> {$subscriber?.weight ?? ''} kg</li>
                        <li>
                            <strong>Schoenmaat:</strong>
                            {$subscriber.size_shoe ?? ''}
                        </li>
                        <li>
                            <strong>Confectiemaat:</strong>
                            {$subscriber.size_dress ?? ''}
                        </li>
                    </ul>
                </div>
                <div class="card-actions justify-end">
                    <button
                        disabled={!hasPaid()}
                        class="btn btn-primary"
                        on:click={() => {
                        showBodyAttrModal = true;
                    }}>
                        <Fa icon={faPenToSquare}/>
                    </button>
                </div>
            </div>
        </div>

        <div class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <h2 class="card-title">Over mij</h2>
                <div class="whitespace-pre-line h-full">
                    {#if !$subscriber.employment_history}
                        <em>
                            Voeg een korte beschrijving toe om nog meer in the picture te staan.
                        </em>
                    {:else}
                        <div class="max-h-[250px] overflow-y-auto">
                            {$subscriber.employment_history}
                        </div>
                    {/if}
                </div>
                <div class="card-actions justify-end">
                    <button
                        disabled={!hasPaid()}
                        class="btn btn-primary"
                        on:click={() => {
                        showAboutModal = true;
                    }}>
                        <Fa icon={faPenToSquare}/>
                    </button
                    >
                </div>
            </div>
        </div>

        <div class="card w-full bg-base-100 shadow-xl lg:col-span-6 lg:col-start-2">
            <div class="card-body items-center text-center">
                <h2 class="card-title">Foto's</h2>

                <SortableList
                    class="col-sm-4 flex flex-wrap gap-4 justify-center"
                    animation={150}
                    onEnd={updatePhotoOrder}
                >
                    {#each photos as photo, i}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div class="imgEditContainer">
                            <img
                                loading="lazy"
                                class="rounded shadow-md"
                                style="width: 200px; height: 200px;"
                                src="https://cma.benfcasting.nl/photos/images/subscriber/thumb{photo.path}"
                                alt="thumbnail"
                                data-id={photo.id}
                                in:fade
                                on:click={showPhoto(photo.id)}
                            />
                            <button
                                on:click={() => deletePhoto(photo.id)}
                                class="btn glass btn-square btn-error imgDel"
                            >
                                <Fa icon={faTrash}/>
                            </button>
                        </div>
                    {/each}
                </SortableList>
                {#if loadingImages}
                    <span class="loading loading-spinner loading-md"></span>
                {/if}
                {#if errorTexts.images}
                    <div class="mt-3 mb-3 text-error">{@html errorTexts.images}</div>
                {/if}
                <Dropzone noClick="{!hasPaid()}" noDrag="{!hasPaid()}" on:drop={handleFilesSelect}/>
            </div>
        </div>

        <div
            class="card w-full  bg-base-100 shadow-xl lg:col-start-2 lg:col-span-6"
        >
            <div class="card-body">
                <h2 class="card-title">Showreel</h2>
                {#if $subscriber.videourl}
                    <div>
                        <VideoPlayer src={$subscriber.videourl} controls={true}/>
                    </div>
                {:else}
                    <div>
                        Voeg een showreel video toe om nog meer in the picture te
                        staan.
                    </div>
                {/if}

                <div class="card-actions justify-end">
                    <button
                        disabled={!hasPaid()}
                        class="btn btn-primary"
                        on:click={() => {
                        showVideoModal = true;
                    }}>
                        <Fa icon={faPenToSquare}/>
                    </button
                    >
                </div>
            </div>
        </div>
    </div>
</div>

{#if showPhotoModal}
    <ImgModal bind:checked={showPhotoModal} selected="{photoToShow}" {photos}/>
{/if}

<input
    type="checkbox"
    id="personalia"
    class="modal-toggle"
    bind:checked={showPersonaliaModal}
/>
<div class="modal glass">
    <div class="modal-box w-11/12 max-w-5xl">
        <h3 class="font-bold text-lg">Personalia</h3>
        {#if errorTexts.personal}
            <div in:scale class="alert mt-2 mb-2 alert-error text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                     viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span>{errorTexts.personal}</span>
            </div>
        {/if}
        <form>
            <label class="label font-bold" for="name-input">
                <span id="name-input" class="label-text">Wat is je naam?</span>
            </label>
            <div class="flex mb-5 justify-start flex-col md:flex-row gap-2">
                <InputText
                    bind:value={$user.firstname}
                    name="firstname"
                    error={$user.errors.firstname}
                    css="w-64"
                    label="Voornaam"
                    placeholder="John"
                    required="true"
                />

                <InputText
                    bind:value={$user.preposition}
                    name="preposition"
                    error={$user.errors.preposistion}
                    css="w-32"
                    label="Tussenvoegsel"
                    placeholder="van"
                />

                <InputText
                    bind:value={$user.lastname}
                    name="lastname"
                    error={$user.errors.lastname}
                    css="w-64"
                    label="Achternaam"
                    placeholder="Doe"
                    required="true"
                />
            </div>

            <div class="flex flex-row gap-2">
                <div>
                    <label for="sex-input" class="label font-bold justify-start gap-2">
                        <span id="sex-input" class="label-text">Geslacht?</span>
                        <span class="text-error">*</span>
                    </label>
                    <select class="select select-bordered w-full max-w-xs" on:change={(e) => {
                        $subscriber.sex = e.target.value;
                    }}>
                        <option selected="{selectedSex === 'M'}" value="M">Man</option>
                        <option selected="{selectedSex === 'F'}" value="F">Vrouw</option>
                        <option selected="{selectedSex === 'T'}" value="T">Anders</option>
                    </select>
                </div>
                <div>
                    <label for="birthdate-input" class="label font-bold justify-start gap-2">
                        <span id="birthdate-input" class="label-text">Je geboortedatum?</span>
                        <span class="text-error">*</span>
                    </label>
                    <input
                        type="date"
                        placeholder="01-01-1970"
                        class="input input-bordered mb-5"
                        value="{$subscriber.birthdate.substring(0, 10)}"
                        on:change={(e) => { $subscriber.birthdate = e.target.value }}
                    />
                </div>
            </div>

            <label for="street-input" class="label font-bold">
                <span id="street-input" class="label-text">Adres?</span>
            </label>
            <div class="flex justify-between md:justify-start flex-row gap-2">
                <InputText
                    type="text"
                    label="Straat"
                    placeholder="Julianalaan"
                    required="true"
                    class="input input-bordered w-44"
                    error={$subscriber.errors.street}
                    bind:value={$subscriber.street}
                />
                <InputText
                    type="text"
                    label="Nummer"
                    placeholder="1"
                    required="true"
                    class="input input-bordered w-28"
                    error={$subscriber.errors.street_number}
                    bind:value={$subscriber.street_number}
                />
                <InputText
                    type="text"
                    label="Toevoeging"
                    placeholder="A"
                    class="input input-bordered w-28"
                    error={$subscriber.errors.street_number_add}
                    bind:value={$subscriber.street_number_add}
                />
            </div>

            <div class="flex justify-between md:justify-start mb-5 flex-row gap-2">
                <InputText
                    label="Postcode"
                    placeholder="1234 AB"
                    type="text"
                    required="true"
                    class="input input-bordered w-40"
                    error={$subscriber.errors.zipcode}
                    bind:value={$subscriber.zipcode}
                />
                <InputText
                    type="text"
                    label="Woonplaats"
                    placeholder="Amsterdam"
                    required="true"
                    class="input input-bordered w-52"
                    error={$subscriber.errors.city}
                    bind:value={$subscriber.city}
                />
            </div>

            <label for="phone-input" class="label font-bold">
                <span id="phone-input" class="label-text">Hoe kunnen we je bereiken?</span>
            </label>

            <div class="flex justify-start flex-col mb-5 md:flex-row gap-2">
                <InputText
                    id="email"
                    disabled="true"
                    value={$user.email}
                    label="Email"
                />
                <InputText
                    id="mobile-input"
                    bind:value={$subscriber.phone_mobile}
                    name="phone"
                    error={$subscriber.errors.phone_mobile}
                    css="w-64"
                    label="Telefoon nummer"
                    required="true"
                    placeholder="0612345678"
                />
            </div>

            <label class="label font-bold" for="personal-details">
                <span id="personal-details" class="label-text">Betalings gegevens</span>
            </label>
            <div class="flex justify-start flex-col mb-5 md:flex-row gap-2">
                <InputText
                    bind:value={$subscriber.bsn}
                    name="BSN"
                    error={$subscriber.errors.bsn}
                    css="w-64"
                    label="Burger Service Nummer"
                    required="true"
                    placeholder="000000000">
                </InputText>

                <InputText
                    bind:value={$subscriber.iban}
                    name="IBAN"
                    error={$subscriber.errors.iban}
                    css="w-64"
                    placeholder="NL00ABNA0123456789"
                    required="true"
                    label="IBAN">
                </InputText>
            </div>
            <label class="label justify-start gap-2 font-bold" for="sub-type">
                <span id="sub-type" class="label-text">Profiel type</span>
                <span class="text-error">*</span>
            </label>
            {#if $subscriber.errors.types}
                <span class="text-error">{$subscriber.errors.types}</span>
            {/if}
            <SubscriberTypes knownTypes="{$page.props.subscriberTypes}" bind:subscriberTypes={$subscriber.types}/>
        </form>
        <div class="modal-action">
            {#if $user.isDirty || $subscriber.isDirty}
                <button
                    class="btn btn-success"
                    class:loading={disabled}
                    on:click={() => saveEdit('personal')}
                    in:fade
                    out:fade>Opslaan
                </button
                >
            {/if}
            <!-- svelte-ignore a11y-"click-events-have-key-events -->
            <button
                class="btn btn-circle btn-outline btn-error"
                {disabled}
                on:click={cancelEdit}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg
                >
            </button>
        </div>
    </div>
</div>

<input
    type="checkbox"
    id="bodyattributes"
    class="modal-toggle"
    bind:checked={showBodyAttrModal}
/>
<div class="modal glass">
    <div class="modal-box w-11/12 max-w-5xl article">
        <h3 class="font-bold text-lg">Hoe zie je eruit?</h3>
        {#if errorTexts.body}
            <div in:scale class="alert mt-2 mb-2 alert-error text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                     viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span>{errorTexts.body}</span>
            </div>
        {/if}
        <form>
            <div class="form-control">
                <label class="label" for="ethnicity">
                    <span class="label-text">Etniciteit</span>
                </label>
                <select
                    id="ethnicity"
                    class="select ml-[3px] select-primary w-64 max-w-xs select-sm"
                    bind:value={$subscriber.ethnicity_id}
                >
                    <option disabled>kies een etniciteit</option>
                    {#each $page.props.ethnicities ?? [] as ethnicity}
                        <option value={ethnicity.id}>{ethnicity.name}</option>
                    {/each}
                </select>
            </div>
            <div class="form-control">
                <label class="label" for="haircolor">
                    <span class="label-text">Haarkleur</span>
                </label>
                <select
                    id="haircolor"
                    class="select ml-[3px] select-primary w-64 max-w-xs select-sm"
                    bind:value={$subscriber.hair_color_id}
                >
                    <option disabled>kies een haarkleur</option>
                    {#each $page.props.haircolors ?? [] as haircolor}
                        <option value={haircolor.id}>{haircolor.name}</option>
                    {/each}
                </select>
            </div>
            <div class="form-control">
                <label class="label" for="eye_color_id">
                    <span class="label-text">Oogkleur</span>
                </label>
                <select
                    id="eye_color_id"
                    class="select ml-[3px] select-primary w-64 max-w-xs select-sm"
                    bind:value={$subscriber.eye_color_id}
                >
                    <option disabled>kies een oogkleur</option>
                    {#each $page.props.eyecolors ?? [] as eyecolor}
                        <option value={eyecolor.id}>{eyecolor.name}</option>
                    {/each}
                </select>
            </div>
            <div class="form-control">
                <label class="label" for="length">
                    <span class="label-text">Lengte</span>
                </label>
                <input
                    id="length"
                    type="range"
                    min="40"
                    max="250"
                    class="range ml-[3px] range-success range-sm"
                    name="length"
                    bind:value={$subscriber.height}
                />
                <span class="ml-[3px] label-text">{$subscriber.height} cm</span>
            </div>

            <div class="form-control">
                <label class="label" for="weight">
                    <span class="label-text">Gewicht</span>
                </label>
                <input
                    id="weight"
                    type="range"
                    min="5"
                    max="210"
                    class="range ml-[3px] range-success range-sm"
                    name="weight"
                    bind:value={$subscriber.weight}
                />
                <span class="ml-[3px] label-text">{$subscriber.weight} kg</span>
            </div>

            <div class="form-control">
                <label class="label" for="shoesize">
                    <span class="label-text">Schoenmaat</span>
                </label>
                <input
                    id="shoesize"
                    type="range"
                    min="16"
                    max="53"
                    step="1"
                    class="range ml-[3px] range-success range-sm"
                    name="weight"
                    bind:value={$subscriber.size_shoe}
                />
                <span class="ml-[3px] label-text">{$subscriber.size_shoe}</span>
            </div>

            <div class="form-control">
                <InputText
                    bind:value={$subscriber.size_dress}
                    name="size_dress"
                    error={$subscriber.errors.size_dress}
                    css="w-64 ml-[3px]"
                    placeholder="42"
                    label="Confectiemaat"
                    type="number"
                    required="true"
                ></InputText>
            </div>
        </form>
        <div class="modal-action">
            {#if $user.isDirty || $subscriber.isDirty}
                <button
                    class="btn btn-success"
                    class:loading={disabled}
                    on:click={() => saveEdit('body-details')}
                    in:fade
                    out:fade>Opslaan
                </button
                >
            {/if}
            <!-- svelte-ignore a11y-"click-events-have-key-events -->
            <button
                class="btn btn-circle btn-outline btn-error"
                {disabled}
                on:click={cancelEdit}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg
                >
            </button>
        </div>
    </div>
</div>

<input
    type="checkbox"
    id="bio-modal"
    class="modal-toggle"
    bind:checked={showAboutModal}
/>
<div class="modal glass">
    <div class="modal-box w-11/12 min-w-lg max-w-5xl article">
        <h3 class="font-bold text-lg">
            Wat vind je belangrijk om over jezelf te vertellen?
        </h3>
        {#if errorTexts.bio}
            <div in:scale class="alert mt-2 mb-2 alert-error text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                     viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span>{errorTexts.bio}</span>
            </div>
        {/if}
        <form>
            <textarea
                class="textarea textarea-bordered w-full mt-4"
                rows="8"
                placeholder="Bio"
                bind:value={$subscriber.employment_history}
            />
        </form>

        <div class="modal-action">
            {#if $user.isDirty || $subscriber.isDirty}
                <button
                    class="btn btn-success"
                    class:loading={disabled}
                    on:click={() => saveEdit('bio')}
                    in:fade
                    out:fade>Opslaan
                </button
                >
            {/if}
            <!-- svelte-ignore a11y-"click-events-have-key-events -->
            <button
                class="btn btn-circle btn-outline btn-error"
                {disabled}
                on:click={cancelEdit}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg
                >
            </button>
        </div>
    </div>
</div>


<input
    type="checkbox"
    id="bodyattributes"
    class="modal-toggle"
    bind:checked={showVideoModal}
/>
<div class="modal glass">
    <div class="modal-box w-11/12 max-w-lg article">
        <h3 class="font-bold text-lg">
            Plak hier het adres van je showreel op bijvoorbeeld youtube of vimeo
        </h3>
        {#if errorTexts.video}
            <div in:scale class="alert mt-2 mb-2 alert-error text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                     viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span>{errorTexts.video}</span>
            </div>
        {/if}
        <form class="mt-5">
            <InputText type="text" placeholder="https://" class="input mt-5 input-bordered w-96"
                       bind:value={$subscriber.videourl}/>

        </form>

        <div class="modal-action">
            {#if $user.isDirty || $subscriber.isDirty}
                <button
                    class="btn btn-success"
                    class:loading={disabled}
                    on:click={() => saveEdit('video')}
                    in:fade
                    out:fade>Opslaan
                </button
                >
            {/if}
            <!-- svelte-ignore a11y-"click-events-have-key-events -->
            <button
                class="btn btn-circle btn-outline btn-error"
                {disabled}
                on:click={cancelEdit}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg
                >
            </button>
        </div>
    </div>
</div>
