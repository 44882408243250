<script context="module">
    export {default as layout} from '../Layout/Layout.svelte'
</script>

<script>
    import MediaQuery from '../Elements/MediaQuery.svelte';
    import RolesRow from '../Elements/RolesRow.svelte';
    import {fade} from 'svelte/transition';
    import {Link, page} from "@inertiajs/svelte";
    import {onMount} from "svelte";

    export let played = [];

    function orderBy() {
        return null;
    }

    onMount(() => {
        played = $page.props.played;
    });

</script>

<svelte:head>
    <title>B&F Casting | Gespeelde Rollen</title>
</svelte:head>

<!--Main -->
<div id="roles-content" class="w-full flex-1 pt-4" in:fade={{ delay: 500 }}>

    <div class="overflow-x-auto w-full">
        {#if !played || played.length < 1}
            <div class="flex-row flex justify-center">
                <div class="card mb-20 max-w-md min-w-fit glass shadow-xl">
                    <div class="card-body">
                        <h2 class="card-title">Je hebt nog geen rollen gespeeld.</h2>
                        <p>Hier komen je gespeelde rollen te staan. Dat heb je nog niet gedaan, dus hier staat nog niks. </p>
                        <div class="card-actions justify-end">
                            <button class="btn mt-5 btn-primary">
                                <Link href="/rollen">Zoek een rol</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        {:else}

            <table class="table w-full">
                <!-- head -->


                <thead>
                <tr>
                    <th></th>

                    <th on:click={() => {orderBy("client_name")}}>Naam</th>

                    <MediaQuery query="(min-width: 1024px)" let:matches>
                        {#if matches}
                            <th>Titel</th>

                            <th on:click={() => {orderBy("date")}}>Datum</th>
                        {/if}
                    </MediaQuery>


                    <th></th>
                </tr>
                </thead>
                <tbody>
                <!-- row  -->
                {#each played as job, i}
                    <RolesRow job={job} checked={job.subscribed} mode="history"></RolesRow>
                {/each}
                </tbody>
                <!-- foot -->
                <tfoot>
                <tr>
                    <th>

                    </th>
                    <th>Naam</th>
                    <MediaQuery query="(min-width: 1024px)" let:matches>
                        {#if matches}
                            <th>Titel</th>

                            <th>Datum</th>
                        {/if}
                    </MediaQuery>

                    <th></th>
                </tr>
                </tfoot>

            </table>
        {/if}
    </div>

</div>
