<script>
    import {fade} from 'svelte/transition';
    import {SortableList} from "@jhubbardsf/svelte-sortablejs";
    import VideoPlayer from "../Elements/VideoPlayer.svelte";
    import Lazy from "svelte-lazy";
    import Fa from "svelte-fa/src/fa.svelte";
    import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
    import {inertia, router} from '@inertiajs/svelte'
    import ImgModal from "../Elements/ImgModal.svelte";

    export let subscriber;
    export let selectionSlug;

    let showPhotoModal = false;
    let selectedPhoto = 0;

    function showPhoto(p) {
        selectedPhoto = p;
        showPhotoModal = true;
    }
</script>

<svelte:head>
    <title>B&F Casting | Selectie Profiel {subscriber.user.fullname}</title>
    <meta name="robots" content="noindex nofollow"/>
    <link rel="icon" type="image/svg" href="https://www.benfcasting.nl/wp-content/uploads/2017/03/icon.jpg"/>
</svelte:head>

<div id="profile-main-content" class="bg-gradient-to-br" in:fade>
    <div class="alert bg-base-100 mb-4 border-none">
        <a use:inertia class="btn btn-primary text-white" href="/selectie/{selectionSlug}">
            <Fa icon={faArrowLeft}/>
            Ga terug naar de selectie</a>
    </div>

    <div class="w-full grid grid-cols-1 justify-center gap-4 lg:grid-cols-8">
        <div class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <h2 class="card-title">Profielfoto</h2>
                <div class="avatar">
                    <div class="w-full">
                        {#if subscriber.photos[0]}
                            <img
                                class="rounded shadow-md h-56"
                                src="https://cma.benfcasting.nl/photos/images/subscriber/thumb{subscriber.photos[0].path}"
                                alt="profiel foto"
                            />
                        {:else }
                            <img src="https://placehold.co/250x250" alt="{subscriber.name}"/>
                        {/if}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <div class="h-full">
                    <h2 class="card-title">Personalia</h2>
                    <ul class="list-none">
                        <li><strong>Naam: </strong>
                            {[
                                subscriber.user.firstname,
                                subscriber.user.preposition,
                                subscriber.user.lastname
                            ].join(" ")}
                        </li>
                        <li>
                            <strong>Leeftijd:</strong> {subscriber.age}
                        </li>
                        <li>
                            <strong>Geslacht:</strong> {subscriber.sex.toLowerCase() === 'm' ? 'Man' : subscriber.sex.toLowerCase() === 'f' ? 'Vrouw' : 'Anders'}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <h2 class="card-title">Lichaamskenmerken</h2>
                <div class="h-full">
                    <ul class="list-none">
                        <li><strong>Etniciteit:</strong> {subscriber.ethnicity?.name ?? ""}</li>
                        <li><strong>Oogkleur:</strong> {subscriber.eye_color?.name ?? ""}</li>
                        <li><strong>Haarkleur:</strong> {subscriber.hair_color?.name ?? ""}</li>
                        <li>
                            <strong>Lengte:</strong>
                            {(subscriber?.height)} cm
                        </li>
                        <li><strong>Gewicht:</strong> {subscriber?.weight ?? ''} kg</li>
                        <li>
                            <strong>Schoenmaat:</strong>
                            {subscriber.size_shoe ?? ''}
                        </li>
                        <li>
                            <strong>Confectiemaat:</strong>
                            {subscriber.size_dress ?? ''}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="card w-full bg-base-100 shadow-xl lg:col-span-2">
            <div class="card-body">
                <h2 class="card-title">Over mij</h2>
                <div class="whitespace-pre-line h-full">
                    <div class="max-h-[250px] overflow-y-auto">
                        {#if subscriber.employment_history}
                            {subscriber.employment_history}
                        {:else }
                            <em>Geen informatie</em>
                        {/if}
                    </div>
                </div>
            </div>
        </div>

        {#if subscriber.photos && subscriber.photos.length > 0}
            <div class="card w-full bg-base-100 shadow-xl lg:col-span-6 lg:col-start-2">
                <div class="card-body items-center text-center">
                    <h2 class="card-title">Foto's</h2>

                    <SortableList
                        class="col-sm-4 flex flex-wrap gap-4 justify-center"
                        animation={150}
                    >
                        {#each subscriber.photos as photo, i}
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="imgEditContainer">
                                <img
                                    loading="lazy"
                                    class="rounded shadow-md"
                                    style="width: 200px; height: 200px;"
                                    src="https://cma.benfcasting.nl/photos/images/subscriber/thumb{photo.path}"
                                    alt="thumbnail"
                                    data-id={photo.order}
                                    in:fade
                                    on:click={showPhoto(photo.order)}
                                />
                            </div>
                        {/each}
                    </SortableList>
                </div>
            </div>
        {/if}


        {#if subscriber.videourl}
            <div class="card w-full  bg-base-100 shadow-xl lg:col-start-2 lg:col-span-6">
                <div class="card-body">
                    <h2 class="card-title">Showreel</h2>

                    <div>
                        <VideoPlayer src={subscriber.videourl} controls={true}/>
                    </div>

                </div>
            </div>
        {/if}
    </div>
</div>

{#if showPhotoModal}
    <div in:fade out:fade>
        <ImgModal bind:checked={showPhotoModal} selected="{selectedPhoto}" photos="{subscriber.photos}"/>
    </div>
{/if}
