<script>
	import {
	  Player,
	  Video,
	  Youtube,
	  Vimeo,
	  Ui,
	  Controls,
	  ControlSpacer,
	  TimeProgress,
	  ScrubberControl,
	  MuteControl,
	  DefaultUi
	} from "@vime/svelte";
	import { onMount } from "svelte";
	import { videoTypeParser } from "../Utils/video";
    import Fa from "svelte-fa/src/fa.svelte";
    import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
	// import CustomControl from "./components/CustomPlayControl.svelte";

	let player;

	export let src;
	export let type;
	export let poster;
	export let controls = true;

	$: _parsedSrc = videoTypeParser(src);
	$: isVideoType = ["video/mp4", "video/webm"].includes(_parsedSrc.type);
	$: isYoutube = _parsedSrc.type === "youtube";
	$: isVimeo = _parsedSrc.type === "vimeo";
</script>

<Player
	bind:this={player}
	isControlsActive={true}
	{controls}
	noClickToPlay

>
	{#if isVideoType}
		<Video crossOrigin="" {poster}>
			<source data-src={src} type={type || _parsedSrc.type} />
		</Video>
	{/if}

	{#if !controls}
		<div class="pointer">
			<DefaultUi noClickToPlay />
		</div>
		<!-- <Ui>
			<div class="pointer">
				<Controls pin="center">
					<ControlSpacer />
					<CustomControl />
					<ControlSpacer />
				</Controls>
			</div>

			<div class="pointer">
				<Controls>
					<ScrubberControl />
				</Controls>
			</div>

			<div class="pointer">
				<Controls fullWidth pin="bottomLeft">
					<TimeProgress />
					<MuteControl />
					<ControlSpacer />
				</Controls>
			</div>
		</Ui> -->
	{/if}

	{#if isYoutube}
		<Youtube videoId={_parsedSrc.id} />
	{:else if isVimeo}
		<Vimeo videoId={_parsedSrc.id} />
	{:else }
        <div class="alert">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span>Kan de showreel niet weergeven. <a class="flex-row btn btn-link gap-2 inline-flex" href="{src}">Bekijk de video hier<Fa icon="{faExternalLink}" /></a> </span>
        </div>
    {/if}
</Player>

<style>
	.pointer {
	  pointer-events: fill;
	}
</style>
