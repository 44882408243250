<script>
    import {onMount} from "svelte";

    export let user;
    export let subscriber;

    let errorMessage = '';

    onMount(() => {
        const fields = getMissingFields();
        errorMessage = buildErrorMessage(getMissingFields());
    })

    function getMissingFields() {
        if (!user || !subscriber) {
            console.log('no user or subscriber')
            return [];
        }

        const missingFields = [];

        if (!user.firstname) {
            missingFields.push('voornaam');
        }
        if (!user.lastname) {
            missingFields.push('achternaam');
        }
        if (!user.email) {
            missingFields.push('emailadres');
        }
        if (!subscriber.street) {
            missingFields.push('adres');
        }
        if (!subscriber.street_number) {
            missingFields.push('huisnummer');
        }
        if (!subscriber.city) {
            missingFields.push('woonplaats');
        }
        if (!subscriber.zipcode) {
            missingFields.push('postcode');
        }
        if (!subscriber.phone || !subscriber.phone_mobile) {
            missingFields.push('telefoonnummer');
        }
        if (!subscriber.birthdate) {
            missingFields.push('geboortedatum');
        }
        if (!subscriber.sex) {
            missingFields.push('geslacht');
        }
        if (!subscriber.iban) {
            missingFields.push('IBAN');
        }
        if (!subscriber.bsn) {
            missingFields.push('BSN');
        }
        if (!subscriber.height) {
            missingFields.push('lengte');
        }
        if (!subscriber.weight) {
            missingFields.push('gewicht');
        }
        if (!subscriber.size_dress) {
            missingFields.push('confectiemaat');
        }
        if (!subscriber.size_shoe) {
            missingFields.push('schoenmaat');
        }
        if (!subscriber.hair_color_id) {
            missingFields.push('haarkleur');
        }
        if (!subscriber.eye_color_id) {
            missingFields.push('oogkleur');
        }
        if (!subscriber.ethnicity_id) {
            missingFields.push('etniciteit');
        }

        return missingFields;
    }

    function buildErrorMessage(missingFields = []) {
        if (missingFields.length === 0) {
            return '';
        }

        let message = 'Er missen nog wat gegevens in uw profiel. Hierdoor kan u niet worden meegenomen in rollen. Vul de volgende velden in: ';

        for (let i = 0; i < missingFields.length; i++) {
            message += missingFields[i];

            if (i === missingFields.length - 2) {
                message += ' en ';
            } else if (i < missingFields.length - 2) {
                message += ', ';
            }
        }

        return message;
    }
</script>

{#if errorMessage}
    <div>
        <div class="alert alert-error">
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                 viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span>{errorMessage}</span>
        </div>
    </div>
{/if}
