<script>
	import { createEventDispatcher, onDestroy } from 'svelte';
    import { fade, fly } from 'svelte/transition';



    export let title = 'Oeps!';
    export let text = 'Heb je de juiste gegevens ingevuld?';
	export let cancelText = "Neeeeeee!"
	export let confirmText = "Okay!!!"
	export let checked = false;

	const dispatch = createEventDispatcher();


	function cancel() {
		dispatch('message', {
			text: 'cancel'
		});
	}

	function confirm() {
		dispatch('message', {
			text: 'confirm'
		});
	}


	let modal;

	const handle_keydown = e => {
		if (e.key === 'Escape') {
			cancel();
		}

		// if (e.key === 'Tab') {
		// 	// trap focus
		// 	const nodes = modal.querySelectorAll('*');
		// 	const tabbable = Array.from(nodes).filter(n => n.tabIndex >= 0);
        //
		// 	let index = tabbable.indexOf(document.activeElement);
		// 	if (index === -1 && e.shiftKey) index = 0;
        //
		// 	index += tabbable.length + (e.shiftKey ? -1 : 1);
		// 	index %= tabbable.length;
        //
		// 	tabbable[index].focus();
		// 	e.preventDefault();
		// }
	};

	const previously_focused = typeof document !== 'undefined' && document.activeElement;

	if (previously_focused) {
		onDestroy(() => {
			previously_focused.focus();
		});
	}

    $: props = (({title, error, ...rest }) => rest)($$props);
</script>

<svelte:window on:keydown={handle_keydown} />

<!-- Put this part before </body> tag -->
<input type="checkbox" id="notify-modal" class="modal-toggle" bind:checked />
<div class="modal modal-middle" in:fade="{{duration: 200}}" out:fade="{{duration: 200}}">
  <div class="modal-box" in:fly="{{ y: 200, duration: 200, delay: 100 }}">
    <h3 class="font-bold text-lg">{title}</h3>
    <p class="py-4">{text}</p>
    <div class="modal-action">
		{#if confirmText}
			<button for="notify-modal" class="btn btn-primary" on:click={confirm}>{confirmText}</button>
		{/if}
		{#if cancelText}
      		<button for="notify-modal" class="btn btn-outline btn-info" on:click={cancel}>{cancelText}</button>
		{/if}

    </div>
  </div>
</div>
