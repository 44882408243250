<script>
    import {SortableList} from "@jhubbardsf/svelte-sortablejs";
    import Lazy from "svelte-lazy";
    import {fade, scale} from "svelte/transition";
    import Dropzone from "../Elements/Dropzone.svelte";
    import Fa from "svelte-fa/src/fa.svelte";
    import {faTrash} from "@fortawesome/free-solid-svg-icons";

    export let photos = [];

    export let error = false;

    let errorText = '';
    let loadingImages = false;
    let files = {
        accepted: [],
        rejected: []
    };

    let previewImages = [];

    export let enabled = true;

    function handleFilesSelect(e) {
        error = false;
        errorText = '';
        loadingImages = true;

        const {acceptedFiles, fileRejections} = e.detail;
        files.accepted = [...files.accepted, ...acceptedFiles];
        files.rejected = [...files.rejected, ...fileRejections];

        if (files.rejected.length > 0) {
            errorText = "Er is een fout opgetreden bij het uploaden van de foto's. Zorg ervoor dat de foto niet groter is dan 2MB";
            loadingImages = false;
        }

        const promises = [];
        files.accepted.forEach((file) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            promises.push(new Promise((resolve, reject) => {
                fileReader.onload = () => {
                    previewImages = [...previewImages, {src: fileReader.result, order: file.name}];
                    photos = [...photos, file];
                    resolve();
                }
            }));
        })

        Promise.all(promises).then(() => {
            loadingImages = false;
            files = {
                accepted: [],
                rejected: []
            };
        })
    }

    function deletePhoto(index) {
        previewImages.splice(index, 1);
        photos.splice(index, 1);

        // Svelte doesn't detect changes in arrays, so we need to reassign the array
        previewImages = previewImages;
        photos = photos;
    }

</script>

<div class="{error ? 'border border-error' : ''}">
    <SortableList
            class="col-sm-4 flex flex-wrap gap-4 justify-center mb-5"
            animation={150}
    >
        {#each previewImages as photo, i}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div in:scale|local={{key: i}} out:scale|local={{key: i}} class="imgEditContainer">
                <Lazy height={300}>
                    <div role="img" on:click={showPhoto(photo.order)}>
                        <img
                                class="rounded shadow-md"
                                style="width: 200px; height: 200px; object-fit: cover; object-position: top;"
                                src="{photo.src}"
                                alt="thumbnail"
                                data-id={photo.order}
                        />
                    </div>
                </Lazy>
                <button on:click={() => deletePhoto(i)} class="btn glass btn-square btn-error imgDel">
                    <Fa icon={faTrash}/>
                </button>
            </div>
        {/each}
    </SortableList>
    {#if loadingImages}
        <span class="loading loading-spinner loading-md"></span>
    {/if}
    {#if errorText}
        <span class="mt-3 mb-3 text-error">{errorText}</span>
    {/if}
    <Dropzone noClick="{!enabled}" noDrag="{!enabled}" on:drop={handleFilesSelect}/>
</div>
